import { Box, Global, Text } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import ReactSelect, { components, StylesConfig } from 'react-select';
import { fontsApi } from '~/api/fontsApi';
import { Font } from '~/models/Font';
import { isEmpty } from 'lodash';

const Option = (props: any) => {
  const { ...data } = props;
  return (
    <components.Control {...props} style={{ border: '0 !important;' }}>
      <Box py='sm' px='xs' sx={{ cursor: 'pointer' }}>
        <Text size='sm' sx={{ fontFamily: data.data.family }}>
          {data.label}
        </Text>
      </Box>
    </components.Control>
  );
};

interface FontPickerProps {
  defaultValue: string;
  onSelect: (value: string) => void;
}

export const FontPicker: React.FC<FontPickerProps> = ({
  defaultValue,
  onSelect,
}: FontPickerProps) => {
  const [fonts, setFonts] = useState<Font[]>([]);
  const [selectedFont, setSelectedFont] = useState<Font>();
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const selectFont = async (fontFamily: string) => {
    setSelectedFont(fonts.find((font) => font.family === fontFamily));
    onSelect(fontFamily);
  };

  const fetchFont = useCallback(async (fontFamily: string) => {
    const font: Font = await fontsApi.fetchFont(fontFamily);
    setSelectedFont({ ...font, value: font?.family, label: font?.family });
  }, []);

  const formatFonts = useCallback((fonts: Font[]) => {
    return fonts.map((font) => ({
      ...font,
      value: font?.family,
      label: font?.family,
    }));
  }, []);

  const reloadFonts = useCallback(async () => {
    setFonts(formatFonts(await fontsApi.fetchFonts(0, limit)));
    setLimit(limit + 10);
    setSkip(skip + 10);
  }, [limit, skip]);

  const searchFonts = async (query: string) => {
    if (query != '' && query.length > 2) {
      setFonts(formatFonts(await fontsApi.searchFonts(query, 0, 10)));
    } else {
      setSkip(0);
      setLimit(10);
      reloadFonts();
    }
  };

  const handleSearchFonts = (query: string) => {
    searchFonts(query);
  };

  useEffect(() => {
    reloadFonts();
  }, []);

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      fetchFont(defaultValue);
    }
  }, [defaultValue]);

  const styles: StylesConfig<
    {
      value: string;
      label: string;
      color: string;
      isFixed?: boolean;
      isDisabled?: boolean;
    },
    false
  > = {
    control: (css) => ({
      ...css,
      border: 0,
      padding: 0,
      boxShadow: 'none',
    }),
    singleValue: (provided, state) => {
      const fontFamily = (state.data as Font).family;
      return { ...provided, fontFamily };
    },
  };

  return (
    <Box my={2} sx={{ width: 200, border: `1px solid #ced4da;`, borderRadius: 4 }}>
      <Global
        styles={[
          ...fonts.map((font) => ({
            '@font-face': {
              fontFamily: font.family,
              src: `url('${font.file}') format("truetype")`,
              fontWeight: 700,
              fontStyle: 'normal',
            },
          })),
          {
            '@font-face': {
              fontFamily: selectedFont?.family,
              src: `url('${selectedFont?.file}') format("truetype")`,
              fontWeight: 700,
              fontStyle: 'normal',
            },
          },
        ]}
      />

      <ReactSelect
        options={fonts as any}
        components={{ Option }}
        onBlur={reloadFonts}
        defaultValue={defaultValue}
        isSearchable
        value={{ ...selectedFont, value: selectedFont?.value, label: selectedFont?.label } as any}
        onChange={(value) => selectFont(value!.value)}
        onInputChange={(value: string) => handleSearchFonts(value)}
        placeholder='בחרו פונט'
        onMenuScrollToBottom={() => {
          reloadFonts();
        }}
        styles={styles}
      />
    </Box>
  );
};
