import { yupResolver } from '@hookform/resolvers/yup';
import { Flex, Select, Space, Text, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconBuildingStore,
  IconLock,
  IconMapPin,
  IconPhone,
  IconQuestionMark,
  IconWifi,
  IconWorld,
  TablerIcon,
} from '@tabler/icons';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { menusApi } from '~/api/menusApi';
import FileUpload from '~/components/FileUpload';
import { ImageTypes } from '~/components/FileUpload/FileUpload';
import config from '~/config';
import { useBusinesses } from '~/contexts/Businesses';
import { FormState } from '~/forms/formState';
import { SocialType, availableMenuKoshers } from '~/models/Business';
import { validationsMessages } from '../validationsMessages';
import { BusinessFormModel, defaultBusiness } from './interfaces';
import { businessValidations } from './validations';

const schema: yup.AnyObjectSchema = yup
  .object()
  .shape(businessValidations, [
    ['whatsapp', 'whatsapp'],
    ['network_name', 'network_name'],
    ['network_password', 'network_password'],
  ])
  .required();

interface socialItem {
  name: SocialType;
  label: string;
  placeholder: string;
  icon: TablerIcon;
}

const socials: socialItem[] = [
  { name: 'twitter', label: 'חשבון Twitter', placeholder: 'Twitter URL', icon: IconBrandTwitter },
  {
    name: 'facebook',
    label: 'חשבון Facebook',
    placeholder: 'Facebook URL',
    icon: IconBrandFacebook,
  },
  {
    name: 'instagram',
    label: 'חשבון Instagram',
    placeholder: 'Instagram URL',
    icon: IconBrandInstagram,
  },
];

interface BusinessFormProps {
  defaultData?: BusinessFormModel;
  slugError: string;
  setForm: React.Dispatch<React.SetStateAction<BusinessFormModel | null>>;
  setFormState: React.Dispatch<React.SetStateAction<FormState | null>>;
}

export const BusinessForm: React.FC<BusinessFormProps> = memo(
  ({ defaultData, slugError, setForm, setFormState }: BusinessFormProps) => {
    const theme = useMantineTheme();
    const { selectedBusiness } = useBusinesses();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const { watch, register, reset, setValue, control, setError, trigger } =
      useForm<BusinessFormModel>({
        defaultValues: { ...defaultBusiness, ...defaultData },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(
          isEditMode
            ? schema.concat(
                yup.object({ defaultMenu: yup.string().required(validationsMessages.required()) }),
              )
            : schema,
        ),
      });

    const { errors, isValid, isDirty, isValidating } = useFormState({ control });

    const { data: menus } = useQuery(['fetchAvailableMenus', selectedBusiness], {
      queryFn: () => {
        return menusApi.fetchMenus(selectedBusiness!.id);
      },
      enabled: !!selectedBusiness,
      onSuccess: (data) => {
        if (isArray(data) && data.length > 1 && defaultData) {
          setEditMode(true);
        }
      },
    });

    const uploadImage = (file: ImageTypes) => {
      setValue('logo', file, { shouldDirty: true, shouldValidate: true });
    };

    useEffect(() => {
      const subscription = watch((data) => {
        if (
          (data.network_password ?? '').trim().length > 0 ||
          ((data.network_password ?? '').trim().length === 0 &&
            (data.network_name ?? '').trim().length === 0)
        ) {
          trigger('network_name');
        }
        setForm(data as BusinessFormModel);
      });
      return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
      if (slugError) {
        setError(
          'slug',
          { type: 'validate', message: 'הסלאג כבר תפוס, בבקשה נסו סלאג אחר!' },
          { shouldFocus: true },
        );
      }
    }, [slugError]);

    useEffect(() => {
      setFormState({ errors, isDirty, isValid });
    }, [errors, isValid, isDirty, isValidating]);

    useEffect(() => {
      if (defaultData && isArray(menus) && menus.length > 1) {
        setEditMode(true);
      }
      reset(defaultData);
    }, [defaultData, menus]);

    return (
      <Flex direction={isMobile ? 'column' : 'row'}>
        <Flex
          direction='column'
          sx={{
            flex: 1,
          }}
        >
          <Text size='lg'>פרטים בסיסיים</Text>
          <TextInput
            placeholder='שם העסק'
            label='שם העסק'
            type='text'
            icon={<IconBuildingStore />}
            withAsterisk
            required
            error={errors.name?.message}
            {...register('name')}
          />

          <Space h='xs' />

          <TextInput
            placeholder='כתובת'
            label='כתובת'
            type='text'
            icon={<IconMapPin />}
            withAsterisk
            required
            error={errors.address?.message}
            {...register('address')}
          />

          <Space h='xs' />

          <TextInput
            placeholder='טלפון'
            label='טלפון'
            type='text'
            icon={<IconPhone />}
            withAsterisk
            required
            error={errors.phone?.message}
            {...register('phone')}
          />

          <Space h='xs' />

          <TextInput
            placeholder='מספר טלפון Whatsapp'
            type='text'
            label='מספר טלפון Whatsapp'
            icon={<IconBrandWhatsapp />}
            error={errors.whatsapp?.message}
            {...register('whatsapp')}
          />

          <Space h='xs' />

          <TextInput
            type='text'
            placeholder='סלאג'
            label='סלאג'
            className='ltr-input'
            rightSection={
              <Tooltip
                dir='rtl'
                label={
                  <>
                    סלאג זה צמד מילים המשמש כקיצור לעסק שלכם. <Space />
                    סלאג יכול להכיל רק תווים באנגלית ומקפים.
                    <Space />
                  </>
                }
                position='top-end'
                withArrow
              >
                <div>
                  <IconQuestionMark size={18} style={{ display: 'block', opacity: 0.5 }} />
                </div>
              </Tooltip>
            }
            icon={<IconWorld />}
            withAsterisk
            required
            error={errors.slug?.message}
            {...register('slug')}
          />

          <Text size='xs' color='dimmed'>
            דוגמה: {config.menuBaseURL}/<b>my-restaurant</b>
          </Text>
          <Space h='xs' />
          <Text dir='ltr' sx={{ color: theme.colors.orange[5] }}>
            {config.menuBaseURL}/{watch('slug')}
          </Text>
          <Space h='xl' />
          <Controller
            name='kosher'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
            }}
            render={({ field }) => (
              <Select
                label='בחירת סוג כשרות'
                placeholder='סוג כשרות'
                data={availableMenuKoshers}
                withAsterisk
                required
                error={errors.kosher?.message}
                {...field}
              />
            )}
          />

          {isEditMode ? (
            <Controller
              name='defaultMenu'
              control={control}
              rules={{
                required: 'שדה זה הוא חובה',
              }}
              render={({ field }) => (
                <Select
                  mt='xl'
                  label='תפריט ברירת מחדל'
                  placeholder='בחר תפריט'
                  defaultValue={selectedBusiness?.defaultMenu}
                  data={menus?.map((menu) => ({ value: menu.slug, label: menu.displayName })) || []}
                  withAsterisk
                  required
                  error={errors.defaultMenu?.message}
                  {...field}
                />
              )}
            />
          ) : null}
        </Flex>
        <Space w='xs' />
        <Flex
          direction='column'
          sx={{
            flex: 1,
          }}
        >
          <Text size='lg'>רשתות חברתיות</Text>
          {socials.map((social) => (
            <TextInput
              key={social.name}
              placeholder={social.placeholder}
              label={social.label}
              icon={React.createElement(social.icon)}
              type='url'
              className='ltr-input'
              error={errors.social ? errors.social[social.name]?.message : ''}
              {...register(`social.${social.name}`)}
            />
          ))}
          <Space h='md' />
          <Text size='lg'>הגדרות WIFI</Text>

          <TextInput
            placeholder='שם רשת'
            type='text'
            label='שם רשת'
            icon={<IconWifi />}
            error={errors.network_name?.message}
            {...register('network_name')}
          />
          <TextInput
            placeholder='סיסמה'
            type='text'
            label='סיסמה'
            icon={<IconLock />}
            error={errors.network_password?.message}
            {...register('network_password')}
          />

          <Space h='md' />
          <Text size='sm'>לוגו</Text>
          <FileUpload defaultImage={watch('logo') ?? null} uploadImage={uploadImage} />
        </Flex>
      </Flex>
    );
  },
);
