import { ActionIcon, MantineSize, Tooltip } from '@mantine/core';
import { TablerIcon } from '@tabler/icons';
import React from 'react';

type IconButtonProps = {
  callback: (...params: any[]) => void;
  icon: TablerIcon;
  size?: MantineSize | number;
  tooltip: string;
};

export const IconButton: React.FC<IconButtonProps> = ({
  callback,
  icon,
  size,
  tooltip,
}: IconButtonProps) => {
  return (
    <Tooltip label={tooltip} py={0} p={4} transition='fade' transitionDuration={200}>
      <ActionIcon ml={4} size='sm' onClick={callback}>
        {React.createElement(icon, { size: size ?? 26 })}
      </ActionIcon>
    </Tooltip>
  );
};
