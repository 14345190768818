import { Flex, Paper, Text, useMantineTheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

interface StatisticsBoxProps {
  title: string;
  count: number;
  lowerText?: string;
}

export const StatisticsBox: React.FC<StatisticsBoxProps> = ({
  title,
  count,
  lowerText,
}: StatisticsBoxProps) => {
  const theme = useMantineTheme();
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);

  useEffect(() => {
    if (end) {
      setStart(end);
    }
    setEnd(count);
  }, [count]);

  return (
    <Paper h='100%' shadow='sm' sx={{ borderRadius: 2 }} withBorder mx='xs' p='xl'>
      <Flex direction='column' justify='center' wrap='nowrap'>
        <Flex direction='row' align='center' wrap='nowrap'>
          <Text size={40} color='#373d3f' weight='bold'>
            <CountUp start={start} end={end} duration={0.5} />
          </Text>
        </Flex>
        <Text color='#373d3f'>{title}</Text>
        {lowerText ? (
          <Text size='xs' color={theme.colors.gray[6]}>
            {lowerText}
          </Text>
        ) : null}
      </Flex>
    </Paper>
  );
};
