import {
  Box,
  Button,
  Flex,
  Grid,
  ScrollArea,
  Select,
  Space,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconChartAreaLine, IconMoodSad } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { useBusinesses } from '~/contexts/Businesses';
import Loading from '~/components/Loading';
import StatisticsBox from './components/StatisticsBox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@tanstack/react-query';
import { analyticsApi } from '~/api/analyticsApi';
import { menusApi } from '~/api/menusApi';
import {
  ceil,
  countBy,
  filter,
  first,
  isEmpty,
  keys,
  map,
  max,
  sortBy,
  uniqBy,
  values,
} from 'lodash';
import dayjs from 'dayjs';
import { Analytic } from '~/models/Analytics';
import he from 'apexcharts/dist/locales/he.json';
import DashboardHeader from '~/components/DashboardHeader';

export const Home = () => {
  const theme = useMantineTheme();
  const { selectedBusiness, businesses } = useBusinesses();
  const [viewableMenu, setViewableMenu] = useState<string>('');
  const {
    data: menus,
    isLoading: menusLoading,
    isRefetching: menusFetching,
  } = useQuery(['fetchMenusStatistics', selectedBusiness], {
    queryFn: () => {
      return menusApi.fetchMenus(selectedBusiness!.id);
    },
    enabled: !!selectedBusiness,
    initialData: [],
  });
  const { data: analytics, isLoading } = useQuery({
    queryKey: ['fetchAnalytics', menus],
    queryFn: async () => {
      return analyticsApi.fetchAnalytics(selectedBusiness!.id, map(menus!, 'slug'));
    },
    enabled: !isEmpty(menus),
    refetchOnWindowFocus: true,
    refetchInterval: 10000 * 60 * 5,
  });

  const viewableAnalytics = useMemo(() => {
    return (
      analytics?.filter(
        (analytic) => isEmpty(viewableMenu) || analytic.menu_slug === viewableMenu,
      ) ?? []
    );
  }, [analytics, viewableMenu]);

  const numberOfVisitors = useMemo(() => {
    return uniqBy(viewableAnalytics, 'visitor_id').length;
  }, [viewableAnalytics]);

  const uniqueVisits = useCallback(
    (subtractDays?: number, menuSlug?: string) =>
      filter(
        uniqBy(
          map(
            menuSlug
              ? viewableAnalytics?.filter((analytic) => analytic.menu_slug === menuSlug) ?? []
              : viewableAnalytics,
            (analytic) => {
              const startDay = dayjs(analytic.created_at).startOf('day');
              return {
                ...analytic,
                created_at: startDay.valueOf(),
                id: `${analytic.menu_slug}_${startDay.valueOf()}_${analytic.visitor_id}`,
              };
            },
          ),
          'id',
        ),
        (analytic: Analytic) =>
          !subtractDays ||
          dayjs(analytic.created_at).isAfter(dayjs().subtract(subtractDays, 'day').startOf('day')),
      ),
    [viewableAnalytics],
  );

  const series = useMemo(
    () => [
      {
        name: '&nbsp;כניסות',
        data: values(countBy(sortBy(uniqueVisits(), 'created_at'), 'created_at')).reverse(),
      },
    ],
    [viewableAnalytics],
  );

  const options = useMemo(
    () =>
      ({
        plotOptions: {
          area: {
            fillTo: 'end',
          },
        },
        noData: {
          text: 'לא נמצאו סטטיסטיקות',
          align: 'center',
        },
        chart: {
          locales: [he],
          defaultLocale: 'he',
          zoom: { enabled: false },
          toolbar: { show: false },
          selection: { enabled: false },
          stacked: false,
          sparkline: { enabled: false },
          id: 'visit-bar',
        },
        dataLabels: { enabled: false },
        stroke: {
          curve: 'smooth',
          colors: [theme.colors.orange[4]],
        },
        markers: {
          colors: theme.colors.orange[5],
        },
        xaxis: {
          tickPlacement: 'between',
          labels: {
            show: !isEmpty(menus),
            style: {
              fontSize: '16',
            },
          },
          categories: keys(countBy(sortBy(uniqueVisits(), 'created_at'), 'created_at'))
            .map((date) => [
              dayjs(Number(date)).format('dddd'),
              dayjs(Number(date)).format('DD/MM/YYYY'),
            ])
            .reverse(),
        },
        fill: {
          type: 'solid',
          colors: [theme.colors.orange[3]],
        },
        yaxis: {
          floating: false,
          opposite: true,
          min: 0,
          max: (max(first(series)!.data) ?? 1) * 1.2,
          forceNiceScale: true,
          labels: {
            show: !isEmpty(menus),
            formatter: (val: number, _) => {
              return ceil(val).toFixed(0);
            },
            align: 'center',
            style: {
              fontSize: '16',
            },
          },
        },
      } as ApexCharts.ApexOptions),
    [viewableAnalytics],
  );

  useEffect(() => {
    setViewableMenu('');
  }, [selectedBusiness]);

  if (
    isEmpty(viewableMenu) &&
    ((isEmpty(menus) && menusFetching) || menusLoading || (isLoading && !isEmpty(menus)))
  ) {
    return (
      <Flex
        direction='column'
        align='center'
        justify='center'
        sx={{
          flex: 1,
        }}
      >
        <Loading text='טוען סטטיסטיקות...' />
      </Flex>
    );
  }

  if (isEmpty(businesses)) {
    return (
      <Flex
        direction='column'
        align='center'
        justify='center'
        sx={{
          flex: 1,
        }}
      >
        <IconMoodSad size={150} color={theme.colors.orange[5]} />
        <Text color={theme.colors.orange[9]}>אוי! נראה שאין לכם עסקים קיימים..</Text>
        <Button mt='xl' variant='outline' component={Link} to='create/business'>
          ליצירת עסק
        </Button>
      </Flex>
    );
  }

  return (
    <Flex direction='column' sx={{ flex: 1, overflow: 'hidden' }}>
      <DashboardHeader title='סטטיסטיקות' icon={IconChartAreaLine} underline={false}>
        <Select
          defaultValue={viewableMenu}
          onChange={(value: string | null) => setViewableMenu(value!)}
          data={[
            { value: '', label: 'כל התפריטים' },
            ...(menus?.map((menu) => ({ value: menu.slug, label: menu.displayName })) ?? []),
          ]}
        />
      </DashboardHeader>
      <ScrollArea type='auto' offsetScrollbars={true} pr='xs'>
        <Grid w='100%' grow>
          <Grid.Col span='auto' style={{ maxHeight: 200 }}>
            <StatisticsBox count={menus?.length ?? 0} title='תפריטים' />
          </Grid.Col>
          <Grid.Col span='auto' style={{ maxHeight: 200 }}>
            <StatisticsBox count={uniqueVisits(1).length ?? 0} title='כניסות היום' />
          </Grid.Col>
          <Grid.Col span='auto' style={{ maxHeight: 200 }}>
            <StatisticsBox count={uniqueVisits().length ?? 0} title='כניסות סה"כ' />
          </Grid.Col>
          <Grid.Col span='auto' style={{ maxHeight: 200 }}>
            <StatisticsBox
              count={numberOfVisitors ?? 0}
              title='מבקרים סה"כ'
              lowerText='* מספר מבקרים משוער'
            />
          </Grid.Col>
        </Grid>
        <Box w='100%' h='100%'>
          {selectedBusiness && selectedBusiness?.active && !isEmpty(menus) ? (
            !isLoading ? (
              <Chart options={options} series={series} width='100%' type='area' height='400px' />
            ) : (
              <Loading text='טוען גרף...' />
            )
          ) : (
            <Flex direction='column' align='center'>
              <Space h={60} />
              <Text size='xl'>לא נמצאו סטטיסטיקות</Text>
            </Flex>
          )}
        </Box>
      </ScrollArea>
    </Flex>
  );
};
