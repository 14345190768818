import { Flex, Space, Text, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { IconMenu2, IconQuestionMark, IconWorld } from '@tabler/icons';
import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import config from '~/config';
import { useBusinesses } from '~/contexts/Businesses';
import { Menu } from '~/models/Menu';
import { MenuSettingsFormModel } from './interfaces';
import { menuSettingsValidations } from './validations';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormState } from '~/forms/formState';

const schema: yup.AnyObjectSchema = yup.object().shape(menuSettingsValidations, []).required();

interface MenuSettingsFormProps {
  menuId: string;
  defaultData: Pick<Menu, 'slug' | 'displayName'>;
  updateMenuSettings: (data: MenuSettingsFormModel) => void;
  setFormState: React.Dispatch<React.SetStateAction<FormState | null>>;
}

export const MenuSettingsForm: React.FC<MenuSettingsFormProps> = ({
  menuId,
  defaultData,
  updateMenuSettings,
  setFormState,
}: MenuSettingsFormProps) => {
  const { register, watch, reset, control } = useForm<MenuSettingsFormModel>({
    defaultValues: defaultData,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const theme = useMantineTheme();
  const { selectedBusiness } = useBusinesses();
  const { errors, isValid, isDirty, isValidating } = useFormState({ control });

  useEffect(() => {
    const subscription = watch((data) => {
      updateMenuSettings(data as MenuSettingsFormModel);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setFormState({ errors, isDirty, isValid });
  }, [errors, isValid, isDirty, isValidating]);

  useEffect(() => {
    reset(defaultData);
  }, [menuId]);

  return (
    <Flex direction='column' align='flex-start'>
      <TextInput
        placeholder='שם התפריט'
        label='שם התפריט'
        icon={<IconMenu2 />}
        defaultValue={defaultData.displayName}
        error={errors.displayName?.message}
        {...register('displayName')}
      />

      <TextInput
        type='text'
        placeholder='סלאג'
        label='סלאג'
        className='ltr-input'
        rightSection={
          <Tooltip
            dir='rtl'
            label={
              <>
                סלאג זה צמד מילים המשמש כקיצור לעסק שלכם. <Space />
                סלאג יכול להכיל רק תווים באנגלית ומקפים.
                <Space />
              </>
            }
            position='top-end'
            withArrow
          >
            <div>
              <IconQuestionMark size={18} style={{ display: 'block', opacity: 0.5 }} />
            </div>
          </Tooltip>
        }
        icon={<IconWorld />}
        withAsterisk
        required
        defaultValue={defaultData.slug}
        error={errors.slug?.message}
        {...register('slug')}
      />
      <Text size='xs' color='dimmed'>
        דוגמה: {config.menuBaseURL}/{selectedBusiness?.slug}/<b>my-restaurant</b>
      </Text>
      <Space h='xs' />
      <Text
        dir='ltr'
        sx={{ color: theme.colors.orange[7] }}
        component='a'
        target='_blank'
        href={`${config.menuBaseURL}/${selectedBusiness?.slug}/${watch('slug')}`}
      >
        {config.menuBaseURL}/{selectedBusiness?.slug}/{watch('slug')}
      </Text>
    </Flex>
  );
};
