import { Button, Flex, Space, Text, TextInput } from '@mantine/core';
import { IconLock, IconMail, IconPhone, IconUser, IconUsers } from '@tabler/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from '@mantine/hooks';
import { RegisterFormModel } from './interfaces';

interface RegisterFormProps {
  isError: boolean;
  isLoading: boolean;
  submitForm: (registerFormModel: RegisterFormModel) => void;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  submitForm,
  isError,
  isLoading,
}: RegisterFormProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<RegisterFormModel>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
    },
  });

  return (
    <Flex direction={isMobile ? 'column' : 'row'}>
      <Flex
        direction='column'
        style={{
          flex: 1,
        }}
      >
        <Flex>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
              validate: (value: string) => (value.trim().length > 1 ? true : 'שם לא תקין'),
            }}
            render={({ field }) => (
              <TextInput
                label='שם פרטי'
                placeholder='שם פרטי'
                icon={<IconUser size={14} />}
                required
                error={errors.firstName?.message}
                {...field}
              />
            )}
          />
          <Space w='xs' />
          <Controller
            name='lastName'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
              validate: (value: string) => (value.trim().length > 1 ? true : 'שם משפחה לא תקין'),
            }}
            render={({ field }) => (
              <TextInput
                label='שם משפחה'
                placeholder='שם משפחה'
                icon={<IconUsers size={14} />}
                error={errors.lastName?.message}
                required
                {...field}
              />
            )}
          />
        </Flex>

        <Controller
          name='phone'
          control={control}
          rules={{
            required: 'שדה זה הוא חובה',
            validate: (value: string) => (value.trim().length > 7 ? true : 'טלפון לא תקין'),
          }}
          render={({ field }) => (
            <TextInput
              label='טלפון'
              placeholder='טלפון'
              icon={<IconPhone size={14} />}
              error={errors.phone?.message}
              required
              {...field}
            />
          )}
        />

        <Controller
          name='email'
          control={control}
          rules={{
            required: 'שדה זה הוא חובה',
            validate: (value: string) => (/^\S+@\S+$/.test(value) ? true : 'אימייל לא תקין'),
          }}
          render={({ field }) => (
            <TextInput
              label='אימייל'
              type='email'
              required
              placeholder='אימייל'
              error={errors.email?.message}
              icon={<IconMail size={14} />}
              {...field}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          rules={{
            required: 'שדה זה הוא חובה',
            validate: (value: string) => (value.trim().length >= 6 ? true : 'סיסמה לא תקינה'),
          }}
          render={({ field }) => (
            <TextInput
              placeholder='סיסמה'
              type='password'
              label='סיסמה'
              required
              error={errors.password?.message}
              icon={<IconLock size={14} />}
              {...field}
            />
          )}
        />
        <Text id='error-message' hidden={!isError} size='sm' color='red'>
          לא ניתן להירשם למערכת
        </Text>
        <Button
          loading={isLoading}
          onClick={() => submitForm(getValues())}
          type='submit'
          color='orange'
          my='sm'
          p={0}
          variant='outline'
          id='signup-button'
        >
          הירשם
        </Button>
      </Flex>
    </Flex>
  );
};
