import { Flex, useMantineTheme } from '@mantine/core';
import React, { memo, useCallback } from 'react';

interface CreateNewNodeButtonProps {
  type: 'item' | 'category';
  text: string;
  parentId?: string;
  addNodeItem: (type: 'addCategory' | 'addNode', props?: { parentId: string }) => void;
}

export const CreateNewNodeButton: React.FC<CreateNewNodeButtonProps> = memo(
  ({ type, text, parentId, addNodeItem }: CreateNewNodeButtonProps) => {
    const theme = useMantineTheme();

    const addNewNode = useCallback(() => {
      if (type === 'item') {
        addNodeItem('addNode', { parentId: parentId! });
      } else {
        addNodeItem('addCategory');
      }
    }, [addNodeItem, parentId]);

    return (
      <Flex
        py='md'
        align='center'
        justify='center'
        sx={{
          color: theme.colors.orange[5],
          cursor: 'pointer',
        }}
        onClick={addNewNode}
      >
        {text}
      </Flex>
    );
  },
);
