import { Box, Button, Divider, Flex, Select, TextInput } from '@mantine/core';
import { useInputState, useMediaQuery, useToggle } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconChevronDown, IconPackgeImport, IconX } from '@tabler/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { cloneDeep, find, get, isEmpty, map, omit } from 'lodash';
import { useCallback, useReducer, useState } from 'react';
import isURL from 'validator/lib/isURL';
import { integrationsApi } from '~/api/integrationsApi';
import { menusApi } from '~/api/menusApi';
import DashboardHeader from '~/components/DashboardHeader';
import Loading from '~/components/Loading';
import UnsupportedFeature from '~/components/UnsupportedFeature';
import { useBusinesses } from '~/contexts/Businesses';
import { MenuNodeEditProvider } from '~/contexts/MenuNodeEdit';
import { validationsMessages } from '~/forms/validationsMessages';
import {
  Menu,
  MenuData,
  MenuNode,
  defaultMenuData,
  newMenuNodeCategory,
  newMenuNodeItem,
} from '~/models/Menu';
import { SaveMenuButton } from '../../components/SaveMenuButton/SaveMenuButton';
import MenuNodeEdit from '../EditMenu/components/MenuNodeEdit';
import { MenuStructure } from '../EditMenu/components/MenuStructure/MenuStructure';
import { CreateMenuModal } from './components/CreateMenuModal/CreateMenuModal';

const dataReducer = (state: MenuData, action: any) => {
  switch (action.type) {
    case 'reorder':
      return action.newOrder;
    case 'addCategory':
      return [...state, { ...newMenuNodeCategory(), id: action.id }];
    case 'deleteCategory':
      return (state as MenuData).filter((category: MenuNode) => category.id !== action.node.id);
    case 'updateNode': {
      const updatedNodeCategoryIndex = state.findIndex((node) => node.id === action.node.parentId);
      state[updatedNodeCategoryIndex].children?.forEach((child: MenuNode, index: number) => {
        if (child.id === action.node.id) {
          state[updatedNodeCategoryIndex].children![index] = {
            ...child,
            ...omit(action.updatedNode, 'id', 'children', 'parentId', 'type'),
          };
        }
      });
      return state;
    }
    case 'deleteNode': {
      const newMenu: MenuData = cloneDeep(state);
      const updatedNodeIndex = newMenu.findIndex((node) => node.id === action.node.parentId);
      newMenu[updatedNodeIndex].children = newMenu[updatedNodeIndex].children?.filter(
        (children) => children.id !== action.node.id,
      );
      return newMenu;
    }
    case 'addNode': {
      const newMenu: MenuData = cloneDeep(state);
      const updatedNodeIndex = newMenu.findIndex((node) => node.id === action.parentId);
      newMenu[updatedNodeIndex].children?.push({
        ...newMenuNodeItem(),
        id: action.id,
      });
      return newMenu;
    }
    case 'updateCategory': {
      const updatedNodeIndex = state.findIndex((node) => node.id === action.node.id);
      const category = state[updatedNodeIndex];
      state[updatedNodeIndex] = {
        ...category,
        ...omit(action.updatedNode, 'id', 'children', 'type'),
      };
      return state;
    }
    case 'initMenu':
      return action.data ?? defaultMenuData;
    default:
      return state;
  }
};

export const Integrations = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [importURL, setImportURL] = useInputState('');
  const [menuData, dispatchMenuData] = useReducer(dataReducer, []);
  const [nodesValidations, setNodesValidation] = useState<
    Record<string, { valid: boolean; errors: any }>
  >({});

  const { selectedBusiness } = useBusinesses();
  const [selectedMenu, setSelectedMenu] = useState<string>();

  const [isCreateMenuModalOpened, toggleCreateMenuModalOpened] = useToggle();

  const closeCreateModal = () => {
    toggleCreateMenuModalOpened();
  };

  const { data: menus, refetch } = useQuery(['fetchIntegrationsMenus', selectedBusiness], {
    queryFn: () => {
      return menusApi.fetchMenus(selectedBusiness!.id);
    },
    enabled: !!selectedBusiness,
  });

  const handleSelectMenu = (slug: string) => {
    if (slug === 'add-new-menu') {
      toggleCreateMenuModalOpened();
    }

    if (menus) {
      const selectedMenu: Menu | undefined = find(menus, { slug });
      if (selectedMenu) {
        setSelectedMenu(selectedMenu.slug);
      }
    }
  };

  const runValidations = useCallback((menuData: MenuData) => {
    map(menuData, (category) => {
      if (isEmpty(get(category, 'title'))) {
        updateValidations(category.id, false, {
          title: {
            type: 'required',
            message: validationsMessages.required(),
          },
        });
      }

      map(category.children, (child) => {
        if (isEmpty(get(child, 'title'))) {
          updateValidations(child.id, false, {
            title: {
              type: 'required',
              message: validationsMessages.required(),
            },
          });
        }
      });
    });
  }, []);

  const importMenuMutation = useMutation({
    mutationKey: ['importMenu'],
    mutationFn: () => {
      return integrationsApi.fetchMenu(importURL);
    },
    onSuccess: (data) => {
      const importedMenu = data as MenuData;
      dispatchMenuData({ type: 'initMenu', data: cloneDeep(importedMenu) });
      showNotification({
        color: 'green',
        title: 'התפריט יובא בהצלחה!',
        message: '',
        icon: <IconCheck size={18} />,
        autoClose: 5000,
      });
      runValidations(importedMenu);
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'אירעה שגיאה ביבוא, אנא נסו מאוחר יותר',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
    },
  });

  const updateValidations = (nodeId: string, valid: boolean, errors: any) => {
    setNodesValidation((nodesValidations) => ({
      ...nodesValidations,
      [nodeId]: { valid, errors },
    }));
  };

  const removeNodesErrors = (nodesIds: string[]) => {
    setNodesValidation((nodesValidations) => omit(nodesValidations, nodesIds));
  };

  const onMenuCreate = async (menuSlug: string) => {
    closeCreateModal();
    await refetch();
    setSelectedMenu(menuSlug);
  };

  return !isMobile ? (
    <Flex direction='column' sx={{ flex: 1 }} mb='md'>
      <DashboardHeader title='יבוא תפריט' icon={IconPackgeImport} />
      <CreateMenuModal
        isOpened={isCreateMenuModalOpened}
        onCreate={onMenuCreate}
        onClose={() => closeCreateModal()}
      />
      <Flex direction='column' align='flex-start'>
        <TextInput
          label='קישור ליבוא'
          placeholder='קישור ליבוא'
          dir='ltr'
          className='ltr-input'
          w='50%'
          value={importURL}
          onChange={setImportURL}
        />
        <Button
          mt='xs'
          disabled={isEmpty(importURL.trim()) || !isURL(importURL)}
          onClick={() => importMenuMutation.mutate()}
        >
          ייבא
        </Button>
      </Flex>
      {importMenuMutation.isLoading ? (
        <Loading text='מייבא תפריט...' />
      ) : !isEmpty(menuData) ? (
        <Flex mah='70%' sx={{ flex: 1, overflow: 'hidden' }}>
          <MenuNodeEditProvider>
            <Flex sx={{ flex: '0.3' }}>
              <MenuStructure
                menuData={menuData}
                dispatchMenuData={dispatchMenuData}
                nodesValidations={nodesValidations}
              />
            </Flex>
            <Divider h='100%' size='sm' variant='dashed' orientation='vertical' />
            <Flex sx={{ flex: '0.7' }}>
              <MenuNodeEdit
                menuData={menuData}
                setNodesValidation={updateValidations}
                dispatchMenuData={dispatchMenuData}
                nodesValidations={nodesValidations}
                removeNodesErrors={removeNodesErrors}
              />
            </Flex>
          </MenuNodeEditProvider>
        </Flex>
      ) : null}
      {!isEmpty(menuData) ? (
        <Flex align='flex-end'>
          <Select
            label='בחרו את התפריט אליו תרצו לייבא'
            data={[
              ...(menus ?? []).map((menu) => ({ value: menu.slug, label: menu.displayName })),
              { value: 'add-new-menu', label: 'צור תפריט חדש' },
            ]}
            rightSection={<IconChevronDown size={14} />}
            value={selectedMenu}
            onChange={(value: string | null) => handleSelectMenu(value!)}
            styles={{ rightSection: { pointerEvents: 'none' } }}
          />
          <Box mx='xs'>
            <SaveMenuButton
              updatedData={{ data: menuData }}
              requestedMenuSlug={selectedMenu}
              isValid={
                !isEmpty(selectedMenu) &&
                !isEmpty(menuData) &&
                !map(Object.values(nodesValidations), (nodeValidation) =>
                  isEmpty(nodeValidation.errors),
                ).includes(false)
              }
            />
          </Box>
        </Flex>
      ) : null}
    </Flex>
  ) : (
    <UnsupportedFeature text='אנחנו מצטערים, אבל אין כרגע תמיכה בעריכת התפריט דרך מסכים קטנים' />
  );
};
