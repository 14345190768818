import { Font } from '~/models/Font';
import { supabase } from '~/supabaseClient';

export const fontsApi = {
  fetchFonts: async (skip: number, limit: number) => {
    return (await supabase.from('fonts').select().range(skip, limit)).data as Font[];
  },
  fetchFont: async (fontFamily: string) => {
    return (await supabase.from('fonts').select().eq('family', fontFamily).single()).data as Font;
  },
  searchFonts: async (search = '', skip?: number, limit?: number) => {
    return (
      await supabase
        .from('fonts')
        .select()
        .ilike('family', `%${search}%`)
        .range(skip ?? 0, limit ?? 100)
    ).data as Font[];
  },
};
