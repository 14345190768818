import { Anchor, Button, Code, Flex, Modal, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useInputState, useToggle } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { businessesApi } from '~/api/businessesApi';
import DashboardHeader from '~/components/DashboardHeader';
import { useBusinesses } from '~/contexts/Businesses';

export const DeleteBusiness = () => {
  const theme = useMantineTheme();
  const queryClient = useQueryClient();
  const { selectedBusiness } = useBusinesses();
  const [businessName, setBusinessName] = useInputState('');
  const [isDeleteModalOpened, toggleDeleteModalOpened] = useToggle();

  const mutation = useMutation({
    mutationFn: async () => {
      return await businessesApi.deleteBusiness(selectedBusiness!.id);
    },
    onSuccess: () => {
      toggleDeleteModalOpened();
      queryClient.invalidateQueries({ queryKey: ['fetchBusinesses'], exact: true });
      queryClient.invalidateQueries({ queryKey: ['fetchMenus'], exact: true });
    },
  });

  useEffect(() => {
    setBusinessName('');
  }, [isDeleteModalOpened]);

  return (
    <Flex
      direction='column'
      sx={{ borderRadius: 4 }}
      bg={theme.colors.red[1]}
      py='xs'
      px='lg'
      mt='xl'
    >
      <Modal
        opened={isDeleteModalOpened}
        onClose={toggleDeleteModalOpened}
        title='מחיקת עסק'
        color='red'
        centered
      >
        <Text size='sm'>
          כתבו את שם העסק &nbsp;
          <Code sx={{ fontWeight: 'bold' }} bg={theme.colors.red[3]}>
            {selectedBusiness?.name}
          </Code>
          &nbsp; על מנת לוודא כי אתם בטוחים שברצונכם למחוק אותו.
        </Text>
        <TextInput mt='sm' placeholder='שם העסק' value={businessName} onChange={setBusinessName} />
        <Flex mt='sm' justify='flex-end'>
          <Button variant='outline' color='red' onClick={() => toggleDeleteModalOpened()}>
            ביטול
          </Button>
          <Button
            onClick={() => mutation.mutate()}
            disabled={mutation.isLoading || selectedBusiness?.name !== businessName}
            ml='xs'
            color='red'
          >
            מחק
          </Button>
        </Flex>
      </Modal>
      <DashboardHeader
        title='מחיקת עסק'
        titleSize='md'
        icon={IconTrash}
        color='red'
        underline={false}
      />
      <Text weight='bold' w='80%'>
        מחיקת עסק הופכת את העסק ללא פעיל. כאשר העסק אינו פעיל הלקוחות שלכם כבר לא יוכלו לגשת
        לתפריטים של העסק הנ&quot;ל. התשלום החודשי של העסק נפסק בהתאם&nbsp;
        <Anchor href='terms' weight='bolder' color='orange'>
          לתקנון של Tafryt
        </Anchor>
        .
      </Text>
      <Button
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => toggleDeleteModalOpened()}
        variant='white'
        color='red'
        mt='xl'
      >
        מחק עסק
      </Button>
    </Flex>
  );
};
