import { omit } from 'lodash';
import { BusinessFormModel } from '~/forms/BusinessForm/interfaces';
import { supabase } from '~/supabaseClient';

export const businessesApi = {
  createBusiness: async (
    formData: BusinessFormModel,
    userId: string,
    logo: string | File | undefined,
    expirationDate: Date,
  ) => {
    return (
      await supabase
        .from('businesses')
        .insert({
          ...formData,
          slug: formData.slug.toLowerCase(),
          userid: userId,
          logo,
          expiration_date: expirationDate,
        })
        .select()
        .throwOnError()
        .limit(1)
        .single()
    ).data;
  },
  fetchAllUserBusinesses: async (userId: string) => {
    return (await supabase.from('businesses').select('*').eq('userid', userId).throwOnError()).data;
  },
  updateBusiness: async (businessId: string, businessData: BusinessFormModel) => {
    return await supabase
      .from('businesses')
      .update({
        ...omit(businessData!, ['id', 'userid', 'created_at', 'active']),
        slug: businessData.slug.toLowerCase(),
      })
      .eq('id', businessId)
      .throwOnError();
  },
  updateDefaultMenu: async (businessId: string, slug: string) => {
    return await supabase
      .from('businesses')
      .update({ defaultMenu: slug.toLowerCase() })
      .eq('id', businessId);
  },
  checkSlugUniqueness: async (slug: string): Promise<boolean> => {
    const { data } = await supabase.from('businesses').select('*').eq('slug', slug.toLowerCase());
    return data?.length === 0;
  },
  deleteBusiness: async (businessId: string) => {
    return await supabase
      .from('businesses')
      .update({
        active: false,
      })
      .eq('id', businessId)
      .throwOnError();
  },
};
