import validator from 'validator';
import * as yup from 'yup';
import { Menu } from '~/models/Menu';
import { validationsMessages } from '../validationsMessages';

export const menuSettingsValidations: Record<keyof Pick<Menu, 'slug' | 'displayName'>, any> = {
  displayName: yup
    .string()
    .min(2, validationsMessages.minLength(2))
    .max(40, validationsMessages.maxLength(40))
    .required(validationsMessages.required()),
  slug: yup
    .string()
    .min(3, validationsMessages.minLength(3))
    .max(25, validationsMessages.maxLength(25))
    .required(validationsMessages.required())
    .test('isSlug', validationsMessages.invalidSlug(), (value: string | undefined) =>
      validator.isSlug(value ?? ''),
    ),
};
