import { Flex, ScrollArea } from '@mantine/core';
import React from 'react';
import { useMenuNodeEdit } from '~/contexts/MenuNodeEdit';
import { MenuData, MenuNodeTypes } from '~/models/Menu';
import MenuCategoryEdit from '../MenuCategoryEdit';
import MenuChildrenEdit from '../MenuChildrenEdit';

const AVAILABLE_NODES: Record<
  MenuNodeTypes,
  (
    menuData: MenuData,
    dispatchMenuData: React.Dispatch<any>,
    nodesValidations: Record<
      string,
      {
        valid: boolean;
        errors: any;
      }
    >,
    setNodeValidation: (valid: boolean, errors: any) => void,
    removeNodesErrors: (nodesIds: string[]) => void,
  ) => JSX.Element
> = {
  category: (
    menuData: MenuData,
    dispatchMenuData: React.Dispatch<any>,
    nodesValidations: Record<
      string,
      {
        valid: boolean;
        errors: any;
      }
    >,
    setNodeValidation,
    removeNodesErrors,
  ) => (
    <MenuCategoryEdit
      menuData={menuData}
      dispatchMenuData={dispatchMenuData}
      setNodeValidation={setNodeValidation}
      nodesValidations={nodesValidations}
      removeNodesErrors={removeNodesErrors}
    />
  ),
  item: (
    menuData: MenuData,
    dispatchMenuData: React.Dispatch<any>,
    nodesValidations,
    setNodeValidation,
    removeNodesErrors,
  ) => (
    <MenuChildrenEdit
      menuData={menuData}
      dispatchMenuData={dispatchMenuData}
      setNodeValidation={setNodeValidation}
      nodesValidations={nodesValidations}
      removeNodesErrors={removeNodesErrors}
    />
  ),
};

interface MenuNodeEditProps {
  menuData: MenuData;
  dispatchMenuData: React.Dispatch<any>;
  setNodesValidation: (nodeId: string, valid: boolean, errors: any) => void;
  nodesValidations: Record<
    string,
    {
      valid: boolean;
      errors: any;
    }
  >;
  removeNodesErrors: (nodesIds: string[]) => void;
}

export const MenuNodeEdit: React.FC<MenuNodeEditProps> = ({
  menuData,
  dispatchMenuData,
  setNodesValidation,
  nodesValidations,
  removeNodesErrors,
}: MenuNodeEditProps) => {
  const { selectedNode } = useMenuNodeEdit();

  const setNodeValidation = (valid: boolean, errors: any) => {
    if (selectedNode) {
      setNodesValidation(selectedNode.id, valid, errors);
    }
  };

  if (selectedNode) {
    return (
      <ScrollArea sx={{ flex: 1 }} type='auto' offsetScrollbars={true} pr='xs'>
        <Flex
          p='xl'
          pr={0}
          sx={{
            flex: 1,
          }}
        >
          {AVAILABLE_NODES[selectedNode.type](
            menuData,
            dispatchMenuData,
            nodesValidations,
            setNodeValidation,
            removeNodesErrors,
          )}
        </Flex>
      </ScrollArea>
    );
  } else {
    return null;
  }
};
