import { Flex, Text, Tooltip } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';
import React, { memo } from 'react';

interface ItemContainerProps {
  title: string;
  isNewItem: boolean;
  newItemRef: React.MutableRefObject<HTMLDivElement>;
}

export const ItemContainer: React.FC<ItemContainerProps> = memo(
  ({ title, isNewItem, newItemRef }: ItemContainerProps) => {
    return (
      <Flex ref={isNewItem ? newItemRef : undefined} align='center'>
        <IconGripVertical style={{ cursor: 'grab' }} />
        <Tooltip label={title} withArrow transition='fade' transitionDuration={200} py={0} p={4}>
          <Text
            p='xs'
            size='sm'
            sx={{
              maxWidth: 200,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Text>
        </Tooltip>
      </Flex>
    );
  },
);
