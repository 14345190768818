import { Button, Flex, Text, useMantineTheme } from '@mantine/core';
import { IconMoodSad } from '@tabler/icons';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  const theme = useMantineTheme();

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      sx={{
        height: '100%',
      }}
    >
      <IconMoodSad size={150} color={theme.colors.orange[5]} />
      <Text color={theme.colors.orange[9]}>אנחנו מצטערים אבל לא מצאנו את מה שחיפשתם</Text>
      <Button mt='xl' variant='outline' component={Link} to='/'>
        חזרה לדף הבית
      </Button>
    </Flex>
  );
};
