import React, { useState, useContext } from 'react';
import { businessesApi } from '~/api/businessesApi';
import { Business } from '~/models/Business';
import { useAuth } from './Auth';
import { useLocalStorage } from '@mantine/hooks';
import { find, first, isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import Loading from '~/components/Loading';

const BusinessesContext = React.createContext<{
  businesses: Business[] | null | undefined;
  selectedBusiness: Business | null;
  selectBusinessById: (businessId: string) => void;
  selectBusiness: (business: Business | null) => void;
}>({
  businesses: null,
  selectedBusiness: null,
  selectBusinessById: () => undefined,
  selectBusiness: () => undefined,
});

export const BusinessesProvider = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const [savedBusiness, setSavedBusiness] = useLocalStorage({ key: 'bid', defaultValue: '' });
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null);
  const { data: businesses, isLoading } = useQuery(['fetchBusinesses'], {
    queryFn: () => businessesApi.fetchAllUserBusinesses(user!.id),
    onSuccess: (businesses: Business[]) => {
      if (selectedBusiness) {
        const currentBusiness: Business = find(businesses, { id: selectedBusiness }) as Business;
        selectBusiness(currentBusiness);
      }

      if (!isEmpty(businesses)) {
        const currentBusiness = find(businesses, { id: savedBusiness });
        selectBusiness(currentBusiness ?? first(businesses)!);
      }
    },
  });

  const selectBusinessById = (selectedBusinessId: string) => {
    if (businesses && selectedBusinessId) {
      const selectedBusiness = find(businesses, { id: selectedBusinessId });
      selectBusiness(selectedBusiness ?? null);
    }
  };

  const selectBusiness = (selectedBusiness: Business | null) => {
    setSelectedBusiness(selectedBusiness);
    setSavedBusiness(selectedBusiness?.id ?? '');
  };

  const value = {
    businesses,
    selectedBusiness,
    selectBusinessById,
    selectBusiness,
  };

  if (isLoading) {
    return <Loading text='טוען עסקים...' />;
  }

  return <BusinessesContext.Provider value={value}>{children}</BusinessesContext.Provider>;
};

export function useBusinesses() {
  return useContext(BusinessesContext);
}
