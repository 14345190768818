import {
  Button,
  Container,
  Flex,
  Group,
  ScrollArea,
  Space,
  Stepper,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconCheck, IconCircleX, IconInfoCircle, IconPlus, IconX } from '@tabler/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '~/contexts/Auth';
import { useBusinesses } from '~/contexts/Businesses';
import DashboardHeader from '~/components/DashboardHeader';
import BusinessForm from '~/forms/BusinessForm';
import { BusinessFormModel } from '~/forms/BusinessForm/interfaces';
import { get, isEmpty, isString } from 'lodash';
import { v4 } from 'uuid';
import { businessesApi } from '~/api/businessesApi';
import { imagesApi } from '~/api/imagesApi';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Business } from '~/models/Business';
import { FormState } from '~/forms/formState';
import dayjs from 'dayjs';
import { closeAllModals, openModal } from '@mantine/modals';

export const CreateBusiness = () => {
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  const { user } = useAuth();
  const [active, setActive] = useState(0);
  const { selectBusiness } = useBusinesses();
  const [form, setForm] = useState<BusinessFormModel | null>(null);
  const [formState, setFormState] = useState<FormState | null>(null);
  const [slugError, setSlugError] = useState<string>('');

  const mutation = useMutation({
    mutationFn: async () => {
      let imageID;
      if (form?.logo && !isString(form?.logo)) {
        const id = v4();
        imageID = await get(await imagesApi.uploadImages([{ id, data: form.logo }]), id);
      }

      const expirationDate = dayjs().add(2, 'weeks').toDate();

      const data: Business = await businessesApi.createBusiness(
        form!,
        user!.id,
        imageID,
        expirationDate,
      );

      queryClient.invalidateQueries({ queryKey: ['fetchBusinesses'], exact: true });
      selectBusiness(data);
      openModal({
        title: '',
        size: 'lg',
        centered: true,
        padding: 'xl',
        children: (
          <Flex direction='column' align='center' justify='center'>
            <Text size='xl'>
              תקופת הנסיון שלך החלה עכשיו ותסתיים בתאריך{' '}
              {dayjs(expirationDate).format('DD/MM/YYYY')}
            </Text>
            <Button mt='xl' fullWidth onClick={() => closeAllModals()}>
              סגור
            </Button>
          </Flex>
        ),
      });

      return data;
    },
    onSuccess: () => {
      setActive((current) => (current < 3 ? current + 1 : current));
      showNotification({
        id: 'create-business',
        color: 'green',
        title: 'העסק נוצר בהצלחה!',
        message: '',
        icon: <IconCheck size={18} />,
        autoClose: 5000,
      });
    },
    onError: () => {
      showNotification({
        id: 'create-business',
        color: 'red',
        title: 'שגיאה! לא ניתן להוסיף עסק חדש!',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
    },
  });

  const checkSlugUniqueness = async (slug: string) => {
    const isUnique = await businessesApi.checkSlugUniqueness(slug);
    if (!isUnique) {
      showNotification({
        id: 'save-business-settings',
        color: 'red',
        title: 'שגיאה! הסלאג כבר תפוס!',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
      setSlugError(slug);
      return false;
    }
    return true;
  };

  const nextStep = async () => {
    if (active === 0 && form?.slug) {
      const isUnique = await checkSlugUniqueness(form.slug);

      if (!isUnique) {
        return isUnique;
      }
      setSlugError('');
      await mutation.mutateAsync();
      setActive(1);
    }
  };

  return (
    <Flex direction='column' sx={{ flex: 1, overflow: 'hidden' }}>
      <DashboardHeader title='יצירת עסק' icon={IconPlus} />
      <ScrollArea type='auto' offsetScrollbars={true} pr='xs'>
        <Stepper active={active}>
          <Stepper.Step
            icon={<IconInfoCircle size={18} />}
            label='פרטים'
            description='מילוי פרטי העסק'
            completedIcon={
              active === 0 &&
              (!formState?.isValid || !formState?.isDirty || !isEmpty(formState?.errors)) ? (
                <IconCircleX />
              ) : null
            }
          >
            <BusinessForm slugError={slugError} setForm={setForm} setFormState={setFormState} />
          </Stepper.Step>
          <Stepper.Step
            icon={<IconCheck size={18} />}
            label='סיום'
            description='גישה מלאה לעריכת העסק'
          >
            <Space h='xl' />
            <Container
              mt='xl'
              fluid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Container
                fluid
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 150,
                  height: 150,
                  borderRadius: '50%',
                  backgroundColor: theme.colors.green[2],
                }}
              >
                <IconCheck size={150} color='green' />
              </Container>
              <Space h='xs' />
              <Text size='xl'>העסק נוצר בהצלחה</Text>
              <Space h='sm' />
              <Button component={Link} to='/create/menu' leftIcon={<IconPlus size={18} />}>
                ליצירת תפריט
              </Button>
            </Container>
          </Stepper.Step>
        </Stepper>
        {active === 0 && (
          <Group position='center' mt='xl'>
            <Button
              loading={mutation.isLoading}
              disabled={
                active === 0 &&
                (!formState?.isValid || !formState?.isDirty || !isEmpty(formState?.errors))
              }
              onClick={nextStep}
              rightIcon={<IconCheck />}
            >
              סיום
            </Button>
          </Group>
        )}
      </ScrollArea>
    </Flex>
  );
};
