import { Button, Flex, ScrollArea, Text, Timeline, useMantineTheme } from '@mantine/core';
import { IconCheck, IconMenu2, IconMoodNeutral, IconRotate2, IconX } from '@tabler/icons';
import { useState } from 'react';
import { useBusinesses } from '~/contexts/Businesses';
import { Menu } from '~/models/Menu';
import { supabase } from '~/supabaseClient';
import dayjs from 'dayjs';
import Loading from '~/components/Loading';
import { isEqual, orderBy } from 'lodash';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useMenu } from '~/contexts/Menu';
import { menusApi } from '~/api/menusApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const MenuRollback = () => {
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  const { selectedBusiness } = useBusinesses();
  const { selectedMenu, selectMenu } = useMenu();
  const [activeMenu, setActiveMenu] = useState<number>(0);

  const { data: menus, isLoading } = useQuery({
    queryKey: ['fetchMenuHistory'],
    queryFn: async () => {
      const menus: Menu[] = await menusApi.fetchMenuHistory(
        selectedBusiness!.id,
        selectedMenu!.slug,
      );
      if (menus) {
        return orderBy(menus, ['active', 'created_at'], ['desc', 'desc']);
      }
      return null;
    },
    enabled: !!selectedBusiness && !!selectedMenu,
  });

  const rollbackMenu = async () => {
    showNotification({
      id: 'rollback-menu',
      title: 'משחזר את התפריט',
      message: 'משחזר את התפריט לנקודת זמן שנבחרה...',
      loading: true,
      autoClose: false,
      disallowClose: true,
    });

    const selectedMenu = menus![activeMenu];

    const { error: activeFalseError } = await supabase
      .from('menus')
      .update({ active: false })
      .eq('businessid', selectedBusiness?.id)
      .eq('slug', selectedMenu.slug);

    if (!activeFalseError) {
      const { error } = await supabase
        .from('menus')
        .update({ active: true })
        .eq('businessid', selectedBusiness?.id)
        .eq('id', selectedMenu.id);

      if (!error) {
        updateNotification({
          id: 'rollback-menu',
          color: 'green',
          title: 'התפריט שוחזר בהצלחה!',
          message: '',
          icon: <IconCheck size={18} />,
          autoClose: 5000,
        });

        queryClient.invalidateQueries({
          queryKey: ['fetchMenuHistory'],
          exact: true,
        });

        queryClient.invalidateQueries({
          queryKey: ['fetchMenus'],
          exact: true,
        });
        queryClient.invalidateQueries({
          queryKey: ['fetchMenu', selectedBusiness, selectedMenu.slug],
          exact: true,
        });
        selectMenu(selectedMenu);
        setActiveMenu(0);
      } else {
        updateNotification({
          id: 'rollback-menu',
          color: 'red',
          title: 'לא ניתן לשחזר את התפריט',
          message: '',
          icon: <IconX size={18} />,
          autoClose: 5000,
        });
      }
    }
  };

  if (isLoading) {
    return <Loading text='טוען היסטוריית תפריט...' />;
  }

  return menus ? (
    <Flex direction='column' justify='start' sx={{ flex: 1 }}>
      {menus.length > 0 ? (
        <>
          <ScrollArea type='auto' offsetScrollbars={true} pr='xs' color={theme.colors.orange[5]}>
            <Timeline active={0} bulletSize={24} lineWidth={2} sx={{ maxHeight: 400 }}>
              {menus.map((menu: Menu, index: number) => (
                <Timeline.Item
                  key={menu.id}
                  sx={{ cursor: 'pointer' }}
                  active={activeMenu === index}
                  onClick={index !== 0 ? () => setActiveMenu(index) : undefined}
                  bullet={activeMenu === index ? <IconCheck size={12} /> : <IconMenu2 size={12} />}
                  title={
                    <Text color={activeMenu === index ? theme.colors.orange[7] : ''}>
                      {index === 0
                        ? 'תפריט נוכחי'
                        : `נוצר בתאריך ${dayjs(menu.created_at).format('DD/MM/YYYY')} בשעה ${dayjs(
                            menu.created_at,
                          ).format('HH:mm:ss')}`}
                    </Text>
                  }
                >
                  <Text size='xs' mt={4}>
                    {`מזהה תפריט: ${menu.id}`}
                  </Text>
                  <Text size='sm' color='dimmed' mt={4}>
                    {`נוצר ${dayjs(menu.created_at).fromNow()}`}
                  </Text>
                </Timeline.Item>
              ))}
            </Timeline>
          </ScrollArea>
          <Button
            my='sm'
            leftIcon={<IconRotate2 />}
            onClick={rollbackMenu}
            disabled={isEqual(selectedMenu, menus[activeMenu])}
            sx={{ alignSelf: 'end' }}
          >
            שחזר
          </Button>
        </>
      ) : (
        <Flex
          direction='column'
          align='center'
          justify='center'
          sx={{
            height: '100%',
          }}
        >
          <IconMoodNeutral size={150} color={theme.colors.orange[5]} />
          <Text color={theme.colors.orange[9]}>לא נמצאו גרסאות נוספות</Text>
        </Flex>
      )}
    </Flex>
  ) : (
    <Loading text='' />
  );
};
