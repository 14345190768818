import { Box, Button, Divider, Flex, ScrollArea, Space } from '@mantine/core';
import { useShallowEffect } from '@mantine/hooks';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck, IconDeviceFloppy, IconQrcode, IconSettings, IconX } from '@tabler/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { get, isEmpty, isString } from 'lodash';
import { useState } from 'react';
import { businessesApi } from '~/api/businessesApi';
import { imagesApi } from '~/api/imagesApi';
import DashboardHeader from '~/components/DashboardHeader';
import Loading from '~/components/Loading';
import { useBusinesses } from '~/contexts/Businesses';
import BusinessForm from '~/forms/BusinessForm';
import { BusinessFormModel } from '~/forms/BusinessForm/interfaces';
import { FormState } from '~/forms/formState';
import ActivateBusiness from './components/ActivateBusiness';
import DeleteBusiness from './components/DeleteBusiness';
import ViewQRCode from './components/ViewQRCode';
import isURL from 'validator/lib/isURL';

export const BusinessSettings = () => {
  const queryClient = useQueryClient();
  const { selectedBusiness, selectBusinessById } = useBusinesses();
  const [form, setForm] = useState<BusinessFormModel | null>(null);
  const [formState, setFormState] = useState<FormState | null>(null);
  const [slugError, setSlugError] = useState<string>('');

  const mutation = useMutation({
    mutationFn: async () => {
      if (selectedBusiness?.slug !== form?.slug && form?.slug) {
        const isUnique = await checkSlugUniqueness(form.slug);
        if (!isUnique) {
          setSlugError(form.slug);
          throw 'שגיאה!';
        }
      }

      showNotification({
        id: 'save-business-settings',
        title: 'שומר הגדרות עסק',
        message: 'המערכת שומרת את הגדרות העסק...',
        loading: true,
        autoClose: false,
        disallowClose: true,
      });

      let imageID;

      if (
        (form?.logo && !isString(form.logo)) ||
        (form?.logo && isString(form.logo) && isURL(form.logo ?? ''))
      ) {
        imageID = await get(
          await imagesApi.uploadImages([{ id: 'image', data: form.logo }]),
          'image',
        );
      }

      const businessData: BusinessFormModel = {
        ...form,
        logo: imageID,
      } as BusinessFormModel;

      return await businessesApi.updateBusiness(selectedBusiness!.id, businessData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchBusinesses'], exact: true });
      if (selectedBusiness) {
        selectBusinessById(selectedBusiness?.id);
      }
      updateNotification({
        id: 'save-business-settings',
        color: 'green',
        title: 'הגדרות העסק עודכנו בהצלחה!',
        message: '',
        icon: <IconCheck size={18} />,
        autoClose: 5000,
      });
    },
    onError: () => {
      updateNotification({
        id: 'save-business-settings',
        color: 'red',
        title: 'לא ניתן לשמור את הגדרות העסק',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
    },
  });

  const checkSlugUniqueness = async (slug: string) => {
    const isUnique = await businessesApi.checkSlugUniqueness(slug);
    if (!isUnique) {
      showNotification({
        id: 'save-business-settings',
        color: 'red',
        title: 'שגיאה! הסלאג כבר תפוס!',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
      setSlugError(slug);
      return false;
    }
    return true;
  };

  useShallowEffect(() => {
    if (selectedBusiness) {
      setForm(selectedBusiness);
    }
  }, [selectedBusiness]);

  return (
    <Flex direction='column' sx={{ flex: 1 }}>
      <Box>
        {selectedBusiness && !selectedBusiness?.active ? <ActivateBusiness /> : null}
        <DashboardHeader title='הגדרות עסק' icon={IconSettings}>
          <Button
            onClick={() => mutation.mutate()}
            loading={mutation.isLoading}
            disabled={!formState?.isValid || !formState?.isDirty || !isEmpty(formState?.errors)}
            leftIcon={<IconDeviceFloppy />}
          >
            שמור שינויים
          </Button>
        </DashboardHeader>
      </Box>
      {selectedBusiness ? (
        <ScrollArea type='auto' offsetScrollbars={true} pr='xs'>
          <BusinessForm
            defaultData={selectedBusiness as BusinessFormModel}
            slugError={slugError}
            setForm={setForm}
            setFormState={setFormState}
          />
          <Space h='xl' />
          <Divider />
          <Space h='xs' />
          <DashboardHeader title='תצוגת QR' icon={IconQrcode} titleSize='md' underline={false} />
          <ViewQRCode slug={form?.slug ?? null} logo={form?.logo ?? null} />
          {/* <Space h='xs' />
          <Divider />
          <Space h='xl' /> */}
          {/* <DashboardHeader title='תשלום' icon={IconCreditCard} titleSize='md' underline={false} /> */}
          {/* {selectedBusiness?.is_paying ? <PaymentDetails /> : null}
          <DashboardHeader
            title='פרטי חיוב'
            icon={IconBuildingStore}
            titleSize='md'
            underline={false}
          /> */}
          {/* {selectedBusiness?.is_paying ? <PayerDetails /> : null} */}
          <Space h='xs' />
          {selectedBusiness?.active ? <DeleteBusiness /> : null}
        </ScrollArea>
      ) : (
        <Loading text='טוען הגדרות עסק..' />
      )}
    </Flex>
  );
};
