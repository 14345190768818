import { FileWithPath } from '@mantine/dropzone';
import { v4 } from 'uuid';
import { Theme } from './Theme';

export type Menu = {
  id: string;
  businessId: string;
  slug: string;
  displayName: string;
  config: MenuConfig;
  data: MenuData;
  theme: Theme;
  active: boolean;
  created_at: string;
};

export type MenuConfig = {
  notice: string;
  showSearch: boolean;
  showFilters: boolean;
};

export type MenuData = MenuNode[];

export type MenuNodeTypes = 'item' | 'category';

export type MenuPropertiesKeys = 'gluten-free' | 'vegetarian' | 'vegan' | 'spicy' | 'pregnant';

export const availableMenuProperties: { value: MenuPropertiesKeys; label: string }[] = [
  { value: 'gluten-free', label: 'ללא גלוטן' },
  { value: 'vegetarian', label: 'צמחוני' },
  { value: 'vegan', label: 'טבעוני' },
  { value: 'spicy', label: 'חריף' },
  { value: 'pregnant', label: 'הריוני' },
];

export type MenuNode = {
  id: string;
  type: MenuNodeTypes;
  title: string;
  description?: string;
  price?: string;
  badge?: string;
  properties?: MenuPropertiesKeys[];
  image?: string | FileWithPath | File;
  children?: MenuNode[];
};

export type EditableMenuNode = MenuNode & { parentId?: string };

export const newMenuNodeCategory = (): Required<
  Omit<MenuNode, 'properties' | 'badge' | 'price'>
> => {
  return {
    type: 'category',
    id: v4(),
    title: '',
    description: '',
    image: '',
    children: [],
  };
};

export const newMenuNodeItem = (): Required<Omit<MenuNode, 'children'>> => {
  return {
    type: 'item',
    id: v4(),
    title: '',
    badge: '',
    description: '',
    price: '',
    image: '',
    properties: [],
  };
};

export const defaultMenuData: MenuData = [
  {
    id: v4(),
    title: 'קטגוריה',
    type: 'category',
    children: [newMenuNodeItem()],
  },
];

export const defaultConfig: MenuConfig = {
  notice: '',
  showSearch: true,
  showFilters: true,
};
