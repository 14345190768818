import * as yup from 'yup';
import { MenuConfig } from '~/models/Menu';
import { validationsMessages } from '../validationsMessages';

export const menuConfigValidations: Record<keyof MenuConfig, any> = {
  notice: yup
    .string()
    .notRequired()
    .when('notice', {
      is: (value: string) => value?.length,
      then: yup.string().max(100, validationsMessages.maxLength(100)),
    }),
  showFilters: yup.bool().notRequired(),
  showSearch: yup.bool().notRequired(),
};
