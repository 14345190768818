import { get } from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Profile } from '~/models/Profile';
import { useAuth } from './Auth';

const ProfileContext = React.createContext<{
  profile: Profile | null;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
}>({
  profile: null,
  setProfile: () => undefined,
});

export const ProfileProvider = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  const [profile, setProfile] = useState<Profile | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!get(user, 'user_metadata.firstName')) {
      navigate('/signupProviders');
    } else {
      setProfile({
        id: user?.id,
        ...(user?.user_metadata as Profile),
        email: user?.user_metadata.email ?? user?.email,
      });
    }
  }, []);

  const value = {
    profile,
    setProfile,
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export function useProfile() {
  return useContext(ProfileContext);
}
