import { AppShell, LoadingOverlay, useMantineTheme } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import AppHeader from './components/AppHeader';
import { useProfile } from '~/contexts/Profile';
import { BusinessesProvider } from '~/contexts/Businesses';
import AppNavbar from './components/AppNavbar';
import { MenuProvider } from '~/contexts/Menu';
import AppFooter from './components/AppFooter';
import Chat from '~/components/Chat';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import { config } from '~/config/config';
import { isEqual } from 'lodash';

export const Dashboard = () => {
  const { profile } = useProfile();
  const theme = useMantineTheme();
  const [isNavbarOpened, toggleNavbar] = useToggle<boolean>();
  const isMobile = useMediaQuery('(max-width: 1200px)');

  return profile ? (
    <BusinessesProvider>
      <MenuProvider>
        <AppShell
          styles={{
            main: {
              background:
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
              overflow: 'hidden',
              display: 'flex',
              height: 0,
              margin: 0,
              paddingBottom: isMobile ? `15%` : `calc(var(--mantine-footer-height, 0px))`,
            },
          }}
          navbarOffsetBreakpoint='sm'
          asideOffsetBreakpoint='sm'
          navbar={<AppNavbar isNavbarOpened={isNavbarOpened} toggleNavbar={toggleNavbar} />}
          header={<AppHeader isNavbarOpened={isNavbarOpened} toggleNavbar={toggleNavbar} />}
          footer={<AppFooter />}
        >
          <Outlet />
          {isEqual(config.mode, 'production') ? <Chat /> : null}
        </AppShell>
      </MenuProvider>
    </BusinessesProvider>
  ) : (
    <LoadingOverlay visible={true} />
  );
};
