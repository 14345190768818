import { Outlet, Route } from 'react-router-dom';
import Login from '~/pages/Login';
import NotFound from '~/pages/NotFound';
import { PrivateRoute } from './components/PrivateRoute';
import { ProfileProvider } from './contexts/Profile';
import Dashboard from './pages/Dashboard';
import PaymentRequired from './pages/Dashboard/components/PaymentRequired';
import BusinessSettings from './pages/Dashboard/pages/BusinessSettings';
import CreateBusiness from './pages/Dashboard/pages/CreateBusiness';
import CreateMenu from './pages/Dashboard/pages/CreateMenu';
import Design from './pages/Dashboard/pages/Design';
import EditMenu from './pages/Dashboard/pages/EditMenu';
import Home from './pages/Dashboard/pages/Home';
import MenuSettings from './pages/Dashboard/pages/MenuSettings';
import Signup from './pages/Signup';
import SignUpProviders from './pages/SignUpProviders';
import Integrations from './pages/Dashboard/pages/Integrations';

interface RouteProps {
  path?: string;
  component: JSX.Element;
  children?: RouteProps[];
}

export const routes: RouteProps[] = [
  {
    path: '',
    component: (
      <PrivateRoute
        component={
          <ProfileProvider>
            <Dashboard />
          </ProfileProvider>
        }
      />
    ),
    children: [
      { path: '', component: <Home /> },
      {
        path: 'create',
        component: <Outlet />,
        children: [
          {
            path: 'business',
            component: <CreateBusiness />,
          },
          {
            path: 'menu',
            component: (
              <PaymentRequired>
                <CreateMenu />
              </PaymentRequired>
            ),
          },
        ],
      },
      {
        path: 'edit',
        component: (
          <PaymentRequired>
            <EditMenu />
          </PaymentRequired>
        ),
      },
      {
        path: 'design',
        component: (
          <PaymentRequired>
            <Design />
          </PaymentRequired>
        ),
      },
      {
        path: 'integrations',
        component: (
          <PaymentRequired>
            <Integrations />
          </PaymentRequired>
        ),
      },
      {
        path: 'settings',
        component: <Outlet />,
        children: [
          {
            path: '',
            component: <>Account Settings</>,
          },
          {
            path: 'business',
            component: (
              <PaymentRequired>
                <BusinessSettings />
              </PaymentRequired>
            ),
          },
          {
            path: 'menu',
            component: (
              <PaymentRequired>
                <MenuSettings />
              </PaymentRequired>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: <Login />,
  },
  {
    path: 'signup',
    component: <Signup />,
  },
  {
    path: 'signupProviders',
    component: <SignUpProviders />,
  },
  { path: '*', component: <NotFound /> },
];

export const renderChildrens = (children: RouteProps[]) => {
  return children?.map((child) => (
    <Route key={child.path} path={child.path} element={child.component}>
      {child.children ? renderChildrens(child.children) : null}
    </Route>
  ));
};
