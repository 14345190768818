import {
  ActionIcon,
  Anchor,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconBrandGoogle } from '@tabler/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '~/contexts/Auth';
import LoginForm from '~/forms/LoginForm';
import { LoginFormModel } from '~/forms/LoginForm/interfaces';

export const Login = () => {
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { user, signIn, signInGoogle, signInFacebook } = useAuth();

  const mutation = useMutation({
    mutationFn: async (loginData: LoginFormModel) => {
      const { data, error } = await signIn(loginData);
      if (error) {
        throw error;
      }
      queryClient.invalidateQueries({ queryKey: ['fetchUser'], exact: true });

      return data;
    },
    onSuccess: () => {
      navigate('/');
    },
  });

  const authMutation = useMutation({
    mutationFn: async (provider: string) => {
      const { data, error } = provider === 'google' ? await signInGoogle() : await signInFacebook();
      if (error) {
        throw error;
      }
      queryClient.invalidateQueries({ queryKey: ['fetchUser'], exact: true });

      return data;
    },
    onSuccess: () => {
      navigate('/');
    },
  });

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/background.webp')`, // backgroundColor: 'rgb(248, 249, 250)',
      }}
    >
      <Center
        p={0}
        m={0}
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Image height={70} fit='contain' src='/images/logo.png' alt='logo' />
        <Title color='white' py='xl' order={2}>
          התחברו על מנת להמשיך
        </Title>
        <Paper
          shadow='xs'
          px={isMobile ? 'sm' : 150}
          py={isMobile ? 'xs' : 50}
          sx={{
            width: isMobile ? '95%' : '',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          bg='#EDEEF5'
        >
          <Text color={theme.colors.orange[5]}>התחברו באמצעות מייל וסיסמה</Text>
          <LoginForm
            isLoading={mutation.isLoading}
            isError={mutation.isError}
            submitForm={(values) => mutation.mutate(values)}
          />
          <Anchor component={Link} color='orange' to='/signup' underline={false}>
            אין לכם משתמש? לחצו כאן להרשמה
          </Anchor>
          <Divider my='xs' label='או המשיכו עם' labelPosition='center' />
          <Flex justify='space-evenly'>
            {/* <ActionIcon
              onClick={() => authMutation.mutate('facebook')}
              radius='xl'
              variant='subtle'
            >
              <IconBrandFacebook size={24} />
            </ActionIcon> */}
            <ActionIcon
              role='button'
              aria-pressed='false'
              aria-label='התחבר באמצעות Google'
              onClick={() => authMutation.mutate('google')}
              radius='xl'
              variant='subtle'
            >
              <IconBrandGoogle size={24} />
            </ActionIcon>
          </Flex>
        </Paper>
      </Center>
    </Box>
  );
};
