import { Divider, Flex, ScrollArea, Space } from '@mantine/core';
import { IconAdjustments, IconQrcode, IconRotate2, IconSettings } from '@tabler/icons';
import { isEmpty, pick } from 'lodash';
import { useEffect, useMemo, useReducer, useState } from 'react';
import DashboardHeader from '~/components/DashboardHeader';
import Loading from '~/components/Loading';
import { useBusinesses } from '~/contexts/Businesses';
import { useMenu } from '~/contexts/Menu';
import { FormState } from '~/forms/formState';
import MenuConfigForm from '~/forms/MenuConfigForm';
import MenuSettingsForm from '~/forms/MenuSettingsForm';
import { MenuSettingsFormModel } from '~/forms/MenuSettingsForm/interfaces';
import SaveMenuButton from '../../components/SaveMenuButton';
import ViewQRCode from '../BusinessSettings/components/ViewQRCode';
import MenuRollback from './components/MenuRollback';

const configReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'update': {
      return { ...state, ...action.updated };
    }
    case 'init':
      return action.data;
    default:
      return state;
  }
};

export const MenuSettings = () => {
  const { selectedMenu } = useMenu();
  const { selectedBusiness } = useBusinesses();
  const [menuSettings, setMenuSettings] = useState<MenuSettingsFormModel | null>(null);
  const [menuConfig, dispatchMenuConfig] = useReducer(configReducer, selectedMenu?.config);
  const [menuConfigFormState, setMenuConfigFormState] = useState<FormState | null>(null);
  const [menuSettingsFormState, setMenuSettingsFormState] = useState<FormState | null>(null);

  useEffect(() => {
    if (selectedMenu) {
      dispatchMenuConfig({ type: 'init', data: selectedMenu.config });
    }

    () => {
      dispatchMenuConfig({ type: 'init' });
    };
  }, [selectedMenu]);

  const menuSettingsValid = useMemo(
    () =>
      !!menuSettingsFormState && menuSettingsFormState?.isDirty
        ? menuSettingsFormState?.isValid && isEmpty(menuSettingsFormState?.errors)
        : true,
    [menuSettingsFormState],
  );

  const menuConfigValid = useMemo(
    () =>
      !!menuConfigFormState && menuConfigFormState?.isDirty
        ? menuConfigFormState?.isValid && isEmpty(menuConfigFormState?.errors)
        : true,
    [menuConfigFormState],
  );

  return (
    <Flex direction='column' sx={{ flex: 1 }}>
      <DashboardHeader icon={IconAdjustments} title='הגדרות תפריט'>
        <SaveMenuButton
          updatedData={{ config: menuConfig, settings: menuSettings! }}
          isValid={
            !!menuConfigFormState &&
            !!menuSettingsFormState &&
            (menuConfigFormState?.isDirty || menuSettingsFormState?.isDirty) &&
            menuSettingsValid &&
            menuConfigValid
          }
        />
      </DashboardHeader>
      {selectedBusiness && selectedMenu && menuConfig ? (
        <ScrollArea type='auto' offsetScrollbars={true} pr='xs'>
          <Flex direction='column'>
            <Flex justify='flex-start'>
              <MenuConfigForm
                menuId={selectedMenu.id}
                defaultData={menuConfig}
                dispatchMenuConfig={dispatchMenuConfig}
                setFormState={setMenuConfigFormState}
              />
            </Flex>

            <Space h='xl' />
            <Divider />
            <Space h='xs' />
            <DashboardHeader title='הגדרות' icon={IconSettings} titleSize='md' underline={false} />
            <Flex justify='start'>
              <MenuSettingsForm
                menuId={selectedMenu.id}
                updateMenuSettings={(values) => setMenuSettings(values!)}
                defaultData={pick(selectedMenu, ['slug', 'displayName'])}
                setFormState={setMenuSettingsFormState}
              />
            </Flex>
            <Space h='xl' />
            <Divider />
            <Space h='xs' />
            <DashboardHeader title='תצוגת QR' icon={IconQrcode} titleSize='md' underline={false} />
            <ViewQRCode
              slug={`${selectedBusiness.slug}/${menuSettings?.slug}` ?? ''}
              logo={selectedBusiness.logo ?? null}
            />
            <Space h='xl' />
            <Divider />
            <Space h='xs' />
            <DashboardHeader title='שחזור' icon={IconRotate2} titleSize='md' underline={false} />
            <Space h='lg' />
            <MenuRollback />
          </Flex>
        </ScrollArea>
      ) : (
        <Loading text='טוען הגדרות תפריט..' />
      )}
    </Flex>
  );
};
