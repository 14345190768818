import { Flex, Paper, useMantineTheme } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
import { css, StyleSheet } from 'aphrodite';
import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { flash } from 'react-animations';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { EditableMenuNode, MenuNode } from '~/models/Menu';
import ItemContainer from './components/ItemContainer';

const styles = StyleSheet.create({
  flash: {
    animationName: flash,
    animationDuration: '1s',
    animationDelay: 500,
  },
});

interface ItemNodeProps {
  nodeChildren: MenuNode;
  childrenProvided: DraggableProvided;
  childrenSnapshot: DraggableStateSnapshot;
  isNewItem: boolean;
  newItemRef: React.MutableRefObject<HTMLDivElement>;
  parentId: string;
  isSelectedNode: boolean;
  setSelectedNode: React.Dispatch<React.SetStateAction<EditableMenuNode | null>>;
  nodesValidations: {
    valid: boolean;
    errors: any;
  };
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export const ItemNode: React.FC<ItemNodeProps> = memo(
  ({
    nodeChildren: children,
    childrenProvided,
    childrenSnapshot,
    isNewItem,
    newItemRef,
    parentId,
    isSelectedNode,
    setSelectedNode,
    nodesValidations,
  }: ItemNodeProps) => {
    const theme = useMantineTheme();

    return (
      <Paper
        shadow='xs'
        withBorder
        ref={childrenProvided.innerRef}
        {...childrenProvided.draggableProps}
        {...childrenProvided.dragHandleProps}
        style={{
          ...getItemStyle(childrenSnapshot.isDragging, childrenProvided.draggableProps.style),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: isSelectedNode ? theme.colors.gray[1] : '',
          cursor: 'pointer',
        }}
        className={isNewItem ? css(styles.flash) : ''}
        onClick={() =>
          setSelectedNode({
            ...children,
            parentId,
          })
        }
        my='xs'
      >
        <ItemContainer title={children.title} isNewItem={isNewItem} newItemRef={newItemRef} />
        {nodesValidations && !nodesValidations.valid && !isEmpty(nodesValidations.errors) ? (
          <Flex mx='sm' align='center'>
            <IconAlertCircle color='red' />
          </Flex>
        ) : null}
      </Paper>
    );
  },
);
