import { ActionIcon, Divider, Flex, Tooltip, useMantineTheme } from '@mantine/core';
import { useMenu } from '~/contexts/Menu';
import MenuStructure from './components/MenuStructure';
import { IconEye, IconPencil } from '@tabler/icons';
import DashboardHeader from '~/components/DashboardHeader';
import Loading from '~/components/Loading';
import MenuNodeEdit from './components/MenuNodeEdit';
import { MenuNodeEditProvider } from '~/contexts/MenuNodeEdit';
import SaveMenuButton from '../../components/SaveMenuButton';
import {
  defaultMenuData,
  MenuData,
  MenuNode,
  newMenuNodeCategory,
  newMenuNodeItem,
} from '~/models/Menu';
import { omit, cloneDeep, isEqual, map, isEmpty } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import UnsupportedFeature from '~/components/UnsupportedFeature';
import { config } from '~/config/config';
import { useBusinesses } from '~/contexts/Businesses';

const dataReducer = (state: MenuData, action: any) => {
  switch (action.type) {
    case 'reorder':
      return action.newOrder;
    case 'addCategory':
      return [...state, { ...newMenuNodeCategory(), id: action.id }];
    case 'deleteCategory':
      return (state as MenuData).filter((category: MenuNode) => category.id !== action.node.id);
    case 'updateNode': {
      const updatedNodeCategoryIndex = state.findIndex((node) => node.id === action.node.parentId);
      state[updatedNodeCategoryIndex].children?.forEach((child: MenuNode, index: number) => {
        if (child.id === action.node.id) {
          state[updatedNodeCategoryIndex].children![index] = {
            ...child,
            ...omit(action.updatedNode, 'id', 'children', 'parentId', 'type'),
          };
        }
      });
      return state;
    }
    case 'deleteNode': {
      const newMenu: MenuData = cloneDeep(state);
      const updatedNodeIndex = newMenu.findIndex((node) => node.id === action.node.parentId);
      newMenu[updatedNodeIndex].children = newMenu[updatedNodeIndex].children?.filter(
        (children) => children.id !== action.node.id,
      );
      return newMenu;
    }
    case 'addNode': {
      const newMenu: MenuData = cloneDeep(state);
      const updatedNodeIndex = newMenu.findIndex((node) => node.id === action.parentId);
      newMenu[updatedNodeIndex].children?.push({
        ...newMenuNodeItem(),
        id: action.id,
      });
      return newMenu;
    }
    case 'updateCategory': {
      const updatedNodeIndex = state.findIndex((node) => node.id === action.node.id);
      const category = state[updatedNodeIndex];
      state[updatedNodeIndex] = {
        ...category,
        ...omit(action.updatedNode, 'id', 'children', 'type'),
      };
      return state;
    }
    case 'initMenu':
      return action.data ?? defaultMenuData;
    default:
      return state;
  }
};

export const EditMenu = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { selectedMenu } = useMenu();
  const { selectedBusiness } = useBusinesses();
  const [menuData, dispatchMenuData] = useReducer(
    dataReducer,
    selectedMenu?.data ?? defaultMenuData,
  );
  const [initialMenuData, setInitialMenuData] = useState<MenuData | null>(null);
  const [nodesValidations, setNodesValidation] = useState<
    Record<string, { valid: boolean; errors: any }>
  >({});

  const updateValidations = (nodeId: string, valid: boolean, errors: any) => {
    setNodesValidation((nodesValidations) => ({
      ...nodesValidations,
      [nodeId]: { valid, errors },
    }));
  };

  const removeNodesErrors = (nodesIds: string[]) => {
    setNodesValidation((nodesValidations) => omit(nodesValidations, nodesIds));
  };

  useEffect(() => {
    if (selectedMenu) {
      dispatchMenuData({ type: 'initMenu', data: cloneDeep(selectedMenu.data) });
      setInitialMenuData(cloneDeep(selectedMenu.data));
    }

    () => {
      dispatchMenuData({ type: 'initMenu' });
      setInitialMenuData(null);
    };
  }, [selectedMenu]);

  return !isMobile ? (
    <Flex direction='column' sx={{ flex: 1 }}>
      <DashboardHeader title='עריכת תפריט' icon={IconPencil}>
        <>
          <Tooltip label='צפה בתפריט'>
            <ActionIcon
              component='a'
              href={`${config.menuBaseURL}/${selectedBusiness?.slug}/${selectedMenu?.slug}` ?? ''}
              target='_blank'
              color={theme.colors.orange[5]}
              mx='md'
              size='lg'
              variant='subtle'
            >
              <IconEye />
            </ActionIcon>
          </Tooltip>
          <SaveMenuButton
            updatedData={{ data: menuData }}
            isValid={
              !isEmpty(menuData) &&
              !isEqual(initialMenuData, menuData) &&
              !map(Object.values(nodesValidations), (nodeValidation) =>
                isEmpty(nodeValidation.errors),
              ).includes(false)
            }
          />
        </>
      </DashboardHeader>
      {selectedMenu ? (
        <Flex sx={{ flex: 1, overflow: 'hidden' }}>
          <MenuNodeEditProvider>
            <Flex sx={{ flex: '0.3' }}>
              <MenuStructure
                menuData={menuData}
                dispatchMenuData={dispatchMenuData}
                nodesValidations={nodesValidations}
              />
            </Flex>
            <Divider h='100%' size='sm' variant='dashed' orientation='vertical' />
            <Flex sx={{ flex: '0.7' }}>
              <MenuNodeEdit
                menuData={menuData}
                setNodesValidation={updateValidations}
                dispatchMenuData={dispatchMenuData}
                nodesValidations={nodesValidations}
                removeNodesErrors={removeNodesErrors}
              />
            </Flex>
          </MenuNodeEditProvider>
        </Flex>
      ) : (
        <Loading text='טוען תפריט..' />
      )}
    </Flex>
  ) : (
    <UnsupportedFeature text='אנחנו מצטערים, אבל אין כרגע תמיכה בעריכת התפריט דרך מסכים קטנים' />
  );
};
