export const validationsMessages = {
  minLength: (min: number) => `שדה זה צריך להכיל לפחות ${min} תווים`,
  maxLength: (max: number) => `שדה זה צריך להיות פחות מ-${max} תווים`,
  required: () => 'שדה זה הוא חובה',
  trim: () => 'שדה זה לא יכול להכיל רווחים מיותרים',
  invalidPhone: () => 'טלפון לא חוקי',
  invalidHexColor: () => 'צבע לא תקין',
  invalidSlug: () => 'סלאג לא חוקי',
  invalidURL: () => 'URL לא חוקי',
  invalidProperty: () => 'תכונה לא קיימת',
  invalidCreditCardNumber: () => 'מספר כרטיס אשראי לא תקין',
  invalidCreditCardMonth: () => 'חודש לא תקין',
  invalidCreditCardYear: () => 'שנה לא תקינה',
  invalidCreditCardCVV: () => 'קוד אבטחה לא תקין',
  requiredDependency: (independentField: string, dependentField: string) =>
    `השדה ${dependentField} הוא חובה כאשר השדה ${independentField} מלא`,
};
