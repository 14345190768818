import { Avatar, Menu, Text } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { IconLogout, IconSettings } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/contexts/Auth';
import BusinessesMenu from './components/BusinessesMenu';

export const UserMenu = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [menuOpened, toggleMenuOpened] = useToggle();

  const openSignoutModal = () =>
    openConfirmModal({
      title: 'התנתקות מהמערכת',
      children: <Text size='sm'>האם את/ה בטוחים שאתם רוצים להתנתק?</Text>,
      centered: true,
      labels: { confirm: 'אישור', cancel: 'ביטול' },
      onCancel: () => {
        return;
      },
      onConfirm: () => handleSignOut(),
    });

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <Menu
      opened={menuOpened}
      onChange={toggleMenuOpened}
      position='right-start'
      trigger='hover'
      openDelay={100}
      closeDelay={400}
    >
      <Menu.Target>
        <Avatar sx={{ cursor: 'pointer' }} radius='xl' />
      </Menu.Target>
      <Menu.Dropdown p='sm' sx={{ zIndex: 400 }}>
        <Menu.Label>פעולות</Menu.Label>
        <BusinessesMenu onSelect={() => toggleMenuOpened(false)} />

        <Menu.Item icon={<IconSettings size={14} />}>הגדרות</Menu.Item>
        <Menu.Divider />
        <Menu.Item color='red' onClick={openSignoutModal} icon={<IconLogout size={14} />}>
          התנתק
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
