import { ActionIcon, Box, Button, Flex, Select, Text, Tooltip } from '@mantine/core';
import { IconChevronDown, IconPlus } from '@tabler/icons';
import { useQuery } from '@tanstack/react-query';
import { find, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { menusApi } from '~/api/menusApi';
import Loading from '~/components/Loading';
import { useBusinesses } from '~/contexts/Businesses';
import { useMenu } from '~/contexts/Menu';

interface MenuSelectorProps {
  closeNavbar: () => void;
}

export const MenuSelector: React.FC<MenuSelectorProps> = ({ closeNavbar }: MenuSelectorProps) => {
  const { selectedBusiness } = useBusinesses();
  const { selectedMenu, selectMenu } = useMenu();

  const { data: menus, isLoading } = useQuery(['fetchMenus', selectedBusiness], {
    queryFn: () => {
      return menusApi.fetchMenus(selectedBusiness!.id);
    },
    enabled: !!selectedBusiness,
  });

  const handleSelectMenu = (slug: string) => {
    if (menus) {
      const selectedMenu = find(menus, { slug });
      if (selectedMenu) {
        closeNavbar();
        selectMenu(selectedMenu);
      }
    }
  };

  if (!selectedBusiness?.active) {
    return null;
  }

  if (isLoading) {
    return <Loading height='max-content' text='טוען תפריטים...' />;
  }

  return !isEmpty(menus) ? (
    <Box>
      <Text size='xs'>בחרו תפריט</Text>
      <Flex align='center' mb='xl'>
        <Select
          data={menus?.map((menu) => ({ value: menu.slug, label: menu.displayName })) || []}
          rightSection={<IconChevronDown size={14} />}
          value={selectedMenu?.slug ?? ''}
          onChange={(value: string | null) => handleSelectMenu(value!)}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          sx={{ flex: 1 }}
        />
        <Tooltip label='יצירת תפריט חדש'>
          <ActionIcon component={Link} to='create/menu' mx='sm' onClick={closeNavbar}>
            <IconPlus size={18} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    </Box>
  ) : (
    <Button
      component={Link}
      to='create/menu'
      mb='xl'
      rightIcon={<IconPlus size={18} />}
      onClick={closeNavbar}
    >
      יצירת תפריט
    </Button>
  );
};
