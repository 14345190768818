import { FileWithPath } from '@mantine/dropzone';

export interface Theme {
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  showDividers: boolean;
  font: string;
  cover?: string | FileWithPath | File | null;
  backgroundImage?: string | FileWithPath | File | null;
}

export const defaultTheme: Theme = {
  backgroundColor: '#ffffff',
  primaryColor: '#000000',
  secondaryColor: '#696969',
  showDividers: false,
  font: 'Open Sans',
  cover: null,
  backgroundImage: null,
};
