import { find, flatMap } from 'lodash';
import { EditableMenuNode, MenuData } from '~/models/Menu';

export const menuNodesUtils = {
  findNode: (menu: MenuData, nodeId: string) => {
    return find(menu, { id: nodeId }) ?? find(flatMap(menu, 'children'), { id: nodeId });
  },
  makeMenuDataEditable: (menu: MenuData) => {
    return menu.map((category: EditableMenuNode) => ({
      ...category,
      children: [
        ...(category.children ?? []).map((child: EditableMenuNode) => ({
          ...child,
          parentId: category.id,
        })),
      ],
    }));
  },
};
