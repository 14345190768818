import dayjs from 'dayjs';
import { Analytic } from '~/models/Analytics';
import { supabase } from '~/supabaseClient';

export const analyticsApi = {
  updateMenuAnalytics: async (businessId: string, oldSlug: string, slug: string) => {
    const { error: updateError } = await supabase
      .from('analytics')
      .update({ menu_slug: slug.toLowerCase() })
      .eq('business_id', businessId)
      .eq('type', 'home')
      .eq('menu_slug', oldSlug);
    if (updateError) {
      throw updateError;
    }
  },
  fetchAnalytics: async (businessId: string, menuSlugs: string[]) => {
    return (
      await supabase
        .from('analytics')
        .select()
        .eq('type', 'home')
        .eq('business_id', businessId)
        .gte('created_at', dayjs().subtract(7, 'day').startOf('day'))
        .in('menu_slug', menuSlugs)
        .throwOnError()
    ).data as Analytic[];
  },
  fetchMenuAnalytics: async (businessId: string, menuSlug: string) => {
    return (
      await supabase
        .from('analytics')
        .select()
        .eq('type', 'home')
        .eq('business_id', businessId)
        .eq('menu_slug', menuSlug)
        .throwOnError()
    ).data as Analytic[];
  },
};
