import { defaultConfig, Menu } from '~/models/Menu';
import { defaultTheme } from '~/models/Theme';
import { supabase } from '~/supabaseClient';

export const menusApi = {
  fetchActiveMenu: async (businessId: string, slug: string): Promise<Menu> => {
    return (
      await supabase
        .from('menus')
        .select('*')
        .eq('businessid', businessId)
        .eq('slug', slug)
        .eq('active', true)
        .throwOnError()
        .limit(1)
        .single()
    ).data;
  },
  fetchMenus: async (businessId: string): Promise<Menu[] | null> => {
    return (
      await supabase
        .from('menus')
        .select()
        .eq('businessid', businessId)
        .eq('active', true)
        .throwOnError()
    ).data;
  },
  fetchMenuHistory: async (businessId: string, slug: string) => {
    return (
      await supabase
        .from('menus')
        .select()
        .eq('businessid', businessId)
        .eq('slug', slug)
        .throwOnError()
    ).data as Menu[];
  },
  createNewMenu: async (businessId: string, menu: Pick<Menu, 'slug' | 'displayName'>) => {
    return (
      await supabase
        .from('menus')
        .insert({
          businessid: businessId,
          slug: menu.slug.toLowerCase(),
          displayName: menu.displayName,
          data: [],
          theme: defaultTheme,
          config: defaultConfig,
        })
        .throwOnError()
        .select()
        .throwOnError()
        .limit(1)
        .single()
    ).data;
  },
  updateMenuSetings: async (
    businessId: string,
    oldSlug: string,
    settings: Pick<Menu, 'slug' | 'displayName'>,
  ): Promise<Menu> => {
    const { error: updateError } = await supabase
      .from('menus')
      .update({ ...settings, slug: settings.slug.toLowerCase() })
      .eq('businessid', businessId)
      .eq('slug', oldSlug);
    if (updateError) {
      throw updateError;
    }

    const { data, error: fetchError } = await supabase
      .from('menus')
      .select()
      .eq('businessid', businessId)
      .eq('slug', settings.slug.toLowerCase())
      .eq('active', true)
      .limit(1)
      .single();

    if (fetchError) {
      throw fetchError;
    }

    return data;
  },
  createMenu: async (businessId: string, menu: Partial<Menu>) => {
    await supabase
      .from('menus')
      .update({ active: false })
      .eq('businessid', businessId)
      .eq('slug', menu.slug);
    return (
      await supabase
        .from('menus')
        .insert({
          businessid: businessId,
          slug: menu.slug,
          displayName: menu.displayName,
          data: menu.data,
          theme: menu.theme,
          config: menu.config,
        })
        .select()
        .single()
    ).data;
  },
};
