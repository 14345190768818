import axios, { AxiosRequestConfig } from 'axios';

const api = {
  get: async <T>(url: string, params?: Record<string, string>): Promise<T> =>
    (await axios.get<T>(url, { params })).data,
  post: async <T>(url: string, payload: unknown, config?: AxiosRequestConfig): Promise<T> =>
    (await axios.post<T>(url, payload, config)).data,
  delete: async <T>(url: string): Promise<T> => (await axios.delete(url)).data,
  put: async <T>(url: string, payload: unknown): Promise<T> =>
    (await axios.put<T>(url, payload)).data,
  patch: async <T>(url: string, payload: unknown): Promise<T> =>
    (await axios.patch<T>(url, payload)).data,
};

export default api;
