import { ActionIcon, Box, Flex, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { IconPlus } from '@tabler/icons';
import { MenuData, MenuNode } from '~/models/Menu';
import './MenuStructure.scss';
import { useMenuNodeEdit } from '~/contexts/MenuNodeEdit';
import { chain, cloneDeep, find, flatMap, isEmpty, pick } from 'lodash';
import { v4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { useScrollIntoView } from '@mantine/hooks';
import CreateNewNodeButton from './components/CreateNewNodeButton';
import CategoryNode from './components/CategoryNode';

export const reorder = (list: MenuData, result: DropResult) => {
  const newMenu: MenuNode[] = cloneDeep(list);
  const sourceIndex = newMenu.findIndex((value) => value.id === result.source.droppableId);
  const destIndex = newMenu.findIndex((value) => value.id === result.destination!.droppableId);
  if (newMenu[sourceIndex]?.children) {
    if (result.destination?.droppableId === 'menu') {
      return newMenu;
    }
    const [removed] = newMenu[sourceIndex]!.children!.splice(result.source.index, 1);
    newMenu[destIndex]!.children!.splice(result.destination!.index, 0, removed);
  } else {
    const [removed] = newMenu.splice(result.source.index, 1);
    newMenu.splice(result.destination!.index, 0, removed);
  }
  return newMenu;
};

export interface DraggingStyle {
  position: 'fixed';
  top: number;
  left: number;
  boxSizing: 'border-box';
  width: number;
  height: number;
  transition: 'none';
  transform?: string | undefined;
  zIndex: number;
  opacity?: number | undefined;
  pointerEvents: 'none';
}

const getListStyle = (isDraggingOver: boolean, backgroundColor?: string) => ({
  background: isDraggingOver ? backgroundColor ?? 'lightblue' : '',
});

interface MenuStructureProps {
  menuData: MenuData;
  dispatchMenuData: React.Dispatch<any>;
  nodesValidations: Record<string, { valid: boolean; errors: any }>;
}

export const MenuStructure: React.FC<MenuStructureProps> = ({
  menuData,
  dispatchMenuData,
  nodesValidations,
}: MenuStructureProps) => {
  const theme = useMantineTheme();
  const { setSelectedNode } = useMenuNodeEdit();
  const [newItem, setNewItem] = useState<{ type: 'addCategory' | 'addNode'; id: string } | null>(
    null,
  );
  const {
    targetRef: newItemRef,
    scrollIntoView,
    scrollableRef,
  } = useScrollIntoView<HTMLDivElement>({
    axis: 'y',
    duration: 250,
    onScrollFinish: () => {
      if (newItem) {
        setNewItem(null);
      }
    },
  });

  const addNodeItem = useCallback(
    (type: 'addCategory' | 'addNode', properties?: Record<string, any>) => {
      const nodeId = v4();
      dispatchMenuData({ type, ...properties, id: nodeId });
      setNewItem({ type, id: nodeId });
    },
    [],
  );

  useEffect(() => {
    if (newItemRef.current) {
      scrollIntoView({ alignment: 'center' });
    }
    if (newItem) {
      if (newItem.type === 'addNode') {
        const child = find(flatMap(menuData, 'children'), { id: newItem.id });
        const category = menuData.find((category) => category.children?.includes(child));
        if (category) {
          setSelectedNode({ ...child, parentId: category.id });
        }
      } else {
        setSelectedNode(find(menuData, { id: newItem.id }) ?? null);
      }
    }
  }, [newItemRef, newItem]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    dispatchMenuData({ type: 'reorder', newOrder: reorder(menuData, result) });
  };

  return (
    <Flex direction='column' px='md' py='md' w='100%' sx={{ overflow: 'hidden' }}>
      <Flex align='center' justify='space-between' pb='xs'>
        <Text size='xl'>קטגוריות</Text>
        <Tooltip label='יצירת קטגוריה חדשה' py={0} p={4} transition='fade' transitionDuration={200}>
          <ActionIcon size='sm' onClick={() => addNodeItem('addCategory')}>
            <IconPlus size={26} />
          </ActionIcon>
        </Tooltip>
      </Flex>
      <Box sx={{ flex: 1, width: '100%', overflow: 'hidden' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='menu' type='category'>
            {(provided, snapshot) => (
              <Flex
                direction='column'
                h='100%'
                sx={{ flex: 1, overflowY: 'hidden' }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Flex
                  ref={scrollableRef}
                  direction='column'
                  sx={{
                    ...getListStyle(snapshot.isDraggingOver, theme.colors.orange[1]),
                    overflowY: 'auto',
                  }}
                >
                  {!isEmpty(menuData) ? (
                    menuData.map((category: MenuNode, index: number) => (
                      <Draggable
                        key={`${category.id}-draggable`}
                        draggableId={category.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <CategoryNode
                            addNodeItem={addNodeItem}
                            newItemRef={newItemRef}
                            nodeCategory={category}
                            nodesValidations={pick(
                              nodesValidations,
                              chain(menuData)
                                .find({ id: category.id })
                                .get('children')
                                .map('id')
                                .value(),
                            )}
                            categoryValidations={nodesValidations[category.id]}
                            provided={provided}
                            snapshot={snapshot}
                            newItem={newItem}
                          />
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <CreateNewNodeButton
                      type='category'
                      text='יצירת קטגוריה חדשה'
                      addNodeItem={addNodeItem}
                    />
                  )}
                  {provided.placeholder}
                </Flex>
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Flex>
  );
};
