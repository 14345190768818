import { Anchor, Flex, Text, useMantineTheme } from '@mantine/core';
import { IconAlertCircle, IconClock, IconPower } from '@tabler/icons';
import dayjs from 'dayjs';
import DashboardHeader from '~/components/DashboardHeader';
import config from '~/config';
import { useBusinesses } from '~/contexts/Businesses';

export const ActivateBusiness = () => {
  const { selectedBusiness } = useBusinesses();
  const theme = useMantineTheme();

  if (!selectedBusiness?.active) {
    return (
      <Flex direction='column' bg={theme.colors.red[1]} py='xs' px='lg' align='center'>
        <DashboardHeader
          title='הפעלת עסק'
          titleSize='md'
          icon={IconPower}
          color='red'
          underline={false}
        />
        <Text lineClamp={4} weight='bold'>
          עסק זה אינו פעיל. על מנת להפעיל אותו בחזרה עליכם ליצור איתנו קשר ב
          <Anchor href={config.baseURL} color='red'>
            דף יצירת הקשר שלנו
          </Anchor>
        </Text>
      </Flex>
    );
  }

  return !selectedBusiness?.is_paying ? (
    <Flex direction='column' bg={theme.colors.red[1]} py='xs' px='lg' align='center'>
      <DashboardHeader
        title={
          dayjs(selectedBusiness?.expiration_date).isAfter(dayjs())
            ? 'תקופת נסיון'
            : 'תקופת הנסיון הסתיימה'
        }
        titleSize='md'
        icon={
          dayjs(selectedBusiness?.expiration_date).isAfter(dayjs()) ? IconClock : IconAlertCircle
        }
        color='red'
        underline={false}
      />
      <Text lineClamp={4} weight='bold'>
        {dayjs(selectedBusiness?.expiration_date).isAfter(dayjs())
          ? `תקופת הנסיון תסתיים בתאריך ${dayjs(selectedBusiness?.expiration_date).format(
              'DD/MM/YYYY',
            )}`
          : `תקופת הנסיון הסתיימה. שלמו עכשיו על מנת להמשיך את החוויה שלכם איתנו!`}
        <Anchor px='sm' href='tel:+9720512051774'>
          לרכישת מנוי התקשרו עכשיו
        </Anchor>
      </Text>
    </Flex>
  ) : null;
};
