import { Box, ColorSwatch, Flex, Grid, Group, Text, useMantineTheme } from '@mantine/core';
import { IconPalette } from '@tabler/icons';
import { useQuery } from '@tanstack/react-query';
import { isNil, omit, omitBy, pick } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { palettesApi } from '~/api/paletteApi';
import DashboardHeader from '~/components/DashboardHeader';
import Loading from '~/components/Loading';
import { Palette } from '~/models/Palette';
import { Theme } from '~/models/Theme';
import { palettesUtils } from '~/utils/palettesUtils';
import './Palettes.scss';

interface PalettesProps {
  setColorPalette: (palette: Palette) => void;
  initialMenuTheme: Theme | null;
}

export const Palettes: React.FC<PalettesProps> = ({
  setColorPalette,
  initialMenuTheme,
}: PalettesProps) => {
  const theme = useMantineTheme();
  const [selectedPaletteId, setSelectedPaletteId] = useState<string>();
  const { data: palettes, isLoading } = useQuery(['fetchPalettes'], {
    queryFn: async () => {
      return palettesApi.fetchPalettes();
    },
    onSuccess: (palettes: Palette[]) => {
      if (initialMenuTheme) {
        setDefaultSelectedPalette(palettes);
      }
    },
  });

  const updatePalette = useCallback(
    (palette: Palette) => {
      setSelectedPaletteId(palette.id);
      setColorPalette(omitBy(omit(palette, 'id'), isNil) as Palette);
    },
    [setColorPalette, initialMenuTheme],
  );

  const setDefaultSelectedPalette = useCallback(
    (palettes: Palette[]) => {
      if (initialMenuTheme) {
        const selectedPalette = palettesUtils.findPaletteByColors(
          palettes,
          pick(initialMenuTheme, ['backgroundColor', 'primaryColor', 'secondaryColor']),
        );

        setSelectedPaletteId(selectedPalette?.id);
      }
    },
    [initialMenuTheme],
  );

  useEffect(() => {
    if (palettes && initialMenuTheme) {
      setDefaultSelectedPalette(palettes);
    }
  }, [initialMenuTheme]);

  return (
    <Flex direction='column'>
      <DashboardHeader title='תבניות עיצוב' icon={IconPalette} titleSize='md' underline={false} />
      {isLoading ? (
        <Loading text='טוען תבניות עיצוב...' />
      ) : (
        <Grid>
          {palettes?.map((palette: Palette, index: number) => (
            <Grid.Col span='auto' xl={2} lg={3} md={4} sm={6} xs={6} key={palette.id}>
              <Text color={theme.colors.orange[5]}>תבנית {index + 1}</Text>
              <Box
                py='xs'
                px='xl'
                onClick={() => updatePalette(palette)}
                sx={{
                  cursor: 'pointer',
                  width: 'max-content',
                  borderWidth: 2,
                  borderRadius: 6,
                  borderStyle: palette.id === selectedPaletteId ? 'solid' : 'dashed',
                  background: palette.id === selectedPaletteId ? theme.colors.orange[1] : '',
                  borderColor:
                    palette.id === selectedPaletteId
                      ? theme.colors.orange[5]
                      : theme.colors.gray[5],
                }}
              >
                <Group spacing='xs' sx={{ width: 'max-content' }}>
                  <ColorSwatch
                    size={36}
                    key={palette.backgroundColor}
                    color={palette.backgroundColor}
                    className='color-swatch'
                  />
                  <ColorSwatch
                    size={36}
                    key={palette.primaryColor}
                    color={palette.primaryColor}
                    className='color-swatch'
                  />
                  <ColorSwatch
                    size={36}
                    key={palette.secondaryColor}
                    color={palette.secondaryColor}
                    className='color-swatch'
                  />
                </Group>
              </Box>
            </Grid.Col>
          ))}
        </Grid>
      )}
    </Flex>
  );
};
