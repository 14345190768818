import { Menu, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useBusinesses } from '~/contexts/Businesses';
import { Business } from '~/models/Business';

interface BusinessesMenuProps {
  onSelect: () => void;
}

export const BusinessesMenu: React.FC<BusinessesMenuProps> = ({
  onSelect,
}: BusinessesMenuProps) => {
  const { selectBusiness, businesses, selectedBusiness } = useBusinesses();
  const navigate = useNavigate();

  const handleSelect = (id: string) => {
    onSelect();
    if (id === 'create') {
      navigate('create/business');
    } else {
      if (selectedBusiness?.id !== id) {
        const business = businesses?.find((business) => business?.id === id);
        selectBusiness(business!);
        navigate(selectedBusiness ? '/' : location.pathname);
      }
    }
  };

  return !isEmpty(businesses) && businesses!.length > 1 ? (
    <Menu trigger='hover' position='right-start' openDelay={100} closeDelay={400}>
      <Menu.Target>
        <Menu.Item>
          <Text sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconChevronRight size={14} />
            בחירת עסק נוכחי
          </Text>
        </Menu.Item>
      </Menu.Target>
      <Menu.Dropdown sx={{ maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}>
        {businesses?.map((business: Business) => (
          <Menu.Item
            p='md'
            key={business.id}
            color={business.id === selectedBusiness?.id ? 'orange' : ''}
            onClick={() => handleSelect(business.id)}
          >
            {business.name}
          </Menu.Item>
        ))}

        <Menu.Divider />
        <Menu.Item onClick={() => handleSelect('create')}>יצירת עסק</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
