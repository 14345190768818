import { Code, Flex, Space, Text, Title, useMantineTheme } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconTrash } from '@tabler/icons';
import { useCallback } from 'react';
import IconButton from '~/components/IconButton';
import MenuNodeForm from '~/forms/MenuNodeForm';
import { useMenuNodeEdit } from '~/contexts/MenuNodeEdit';
import { MenuData } from '~/models/Menu';
import { menuNodesUtils } from '~/utils/menuNodesUtils';

interface MenuChildrenEditProps {
  menuData: MenuData;
  dispatchMenuData: React.Dispatch<any>;
  setNodeValidation: (valid: boolean, errors: any) => void;
  nodesValidations: Record<
    string,
    {
      valid: boolean;
      errors: any;
    }
  >;
  removeNodesErrors: (nodesIds: string[]) => void;
}

export const MenuChildrenEdit: React.FC<MenuChildrenEditProps> = ({
  menuData,
  dispatchMenuData,
  setNodeValidation,
  nodesValidations,
  removeNodesErrors,
}: MenuChildrenEditProps) => {
  const theme = useMantineTheme();
  const { selectedNode, setSelectedNode } = useMenuNodeEdit();

  const deleteNode = useCallback(() => {
    if (selectedNode) {
      removeNodesErrors([selectedNode.id]);
    }
    dispatchMenuData({ type: 'deleteNode', node: selectedNode });
    setSelectedNode(null);
  }, [selectedNode, menuData]);

  const openNodeDeleteModal = useCallback(
    () =>
      openConfirmModal({
        title: 'הסר פריט',
        children: (
          <Text size='sm'>
            האם ברצונך להסיר את הפריט
            <Code color='orange' dir='rtl' sx={{ fontFamily: theme.fontFamily }}>
              {menuNodesUtils.findNode(menuData, selectedNode?.id ?? '')?.title}
            </Code>
            ?
          </Text>
        ),
        labels: { confirm: 'אישור', cancel: 'ביטול' },
        onCancel: () => {
          return;
        },
        onConfirm: () => deleteNode(),
      }),
    [selectedNode, menuData],
  );

  return selectedNode ? (
    <Flex direction='column' pl='lg' sx={{ flex: 1 }}>
      <Flex align='center' justify='space-between' sx={{ flex: 1 }}>
        <Title order={2}>עריכת פריט</Title>
        <IconButton tooltip='מחק פריט' callback={openNodeDeleteModal} icon={IconTrash} />
      </Flex>
      <Space h='md' />

      <MenuNodeForm
        menuType='item'
        dispatchMenuData={dispatchMenuData}
        defaultData={selectedNode}
        setIsValid={setNodeValidation}
        nodeValidations={nodesValidations[selectedNode!.id]}
      />
    </Flex>
  ) : null;
};
