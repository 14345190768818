import { Flex, Modal } from '@mantine/core';
import React from 'react';
import { CreateMenu } from '../../../CreateMenu/CreateMenu';

interface CreateMenuModalProps {
  isOpened: boolean;
  onClose: () => void;
  onCreate?: (menuSlug: string) => void;
  description?: React.ReactNode;
}

export const CreateMenuModal: React.FC<CreateMenuModalProps> = ({
  isOpened,
  onClose,
  onCreate,
}: CreateMenuModalProps) => {
  return (
    <Modal opened={isOpened} onClose={onClose} centered overlayOpacity={0.55} overlayBlur={2}>
      <Flex direction='column' align='flex-start' sx={{ flex: 1 }} px='xl'>
        <CreateMenu onCreate={onCreate} />
      </Flex>
    </Modal>
  );
};
