import { FileWithPath } from '@mantine/dropzone';
import { SocialType } from '~/models/Business';

export interface BusinessFormModel {
  name: string;
  address: string;
  phone: string;
  whatsapp?: string;
  slug: string;
  kosher: string;
  social: Record<SocialType, string>;
  network_name?: string;
  network_password?: string;
  logo?: string | FileWithPath | File | null;
  defaultMenu?: string;
}

export const defaultSocial: Record<SocialType, string> = {
  facebook: '',
  instagram: '',
  twitter: '',
};

export const defaultBusiness: BusinessFormModel = {
  name: '',
  slug: '',
  phone: '',
  address: '',
  kosher: '',
  whatsapp: '',
  network_name: '',
  network_password: '',
  social: { twitter: '', facebook: '', instagram: '' },
  logo: null,
  defaultMenu: '',
};
