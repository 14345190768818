import { Box, Center, Image, Paper, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/contexts/Auth';
import RegisterProvidersForm from '~/forms/RegisterProvidersForm';
import { RegisterProvidersFormModel } from '~/forms/RegisterProvidersForm/interfaces';

export const SignupProviders = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const [defaultName, setDefaultName] = useState<string>('');

  const registerProvidersMutation = useMutation({
    mutationFn: async (registerData: RegisterProvidersFormModel) => {
      const { data, error } = await updateUser(registerData);

      if (error) {
        throw error;
      }

      return data.user;
    },
    onSuccess: () => {
      navigate('/');
    },
    retry: 0,
  });

  useEffect(() => {
    if (!user || (user && get(user, 'user_metadata.firstName'))) {
      navigate('/');
    } else {
      setDefaultName(get(user, 'user_metadata.name') ?? '');
    }
  }, [user]);

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: 'rgb(248, 249, 250)' }}>
      <Center
        p={0}
        m={0}
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Image height={70} fit='contain' src='/images/logo.png' alt='logo' />
        <Title color='orange' py='xl' order={2}>
          המשך הרשמה
        </Title>
        <Paper
          shadow='xs'
          px={isMobile ? 'sm' : 150}
          py={isMobile ? 'xs' : 50}
          sx={{
            width: isMobile ? '95%' : '',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          bg='#EDEEF5'
        >
          <Text color={theme.colors.orange[5]}>מלאו את הפרטים שלכם על מנת להירשם למערכת</Text>
          <RegisterProvidersForm
            defaultName={defaultName}
            isError={registerProvidersMutation.isError}
            isLoading={registerProvidersMutation.isLoading}
            submitForm={(values) => registerProvidersMutation.mutate(values)}
          />
        </Paper>
      </Center>
    </Box>
  );
};
