import { Divider, MediaQuery, Navbar, NavLink, Text, useMantineTheme } from '@mantine/core';
import {
  IconAdjustments,
  IconChartAreaLine,
  IconPackgeImport,
  IconPencil,
  IconPlus,
  IconSettings,
  IconWand,
} from '@tabler/icons';
import { isEmpty } from 'lodash';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useBusinesses } from '~/contexts/Businesses';
import { useMenu } from '~/contexts/Menu';
import MenuSelector from './MenuSelector';

interface TabItem {
  label: string;
  path: string;
  icon: React.ReactNode;
  businessRequired?: boolean;
  menuRequired?: boolean;
}

const menuTabs: TabItem[] = [
  {
    label: 'עריכת תפריט',
    path: 'edit',
    icon: <IconPencil size={24} stroke={1.5} />,
    menuRequired: true,
  },
  {
    label: 'עיצוב תפריט',
    path: 'design',
    icon: <IconWand size={24} stroke={1.5} />,
    menuRequired: true,
  },
  {
    label: 'יבוא תפריט',
    path: 'integrations',
    icon: <IconPackgeImport size={24} stroke={1.5} />,
    menuRequired: true,
  },
  {
    label: 'הגדרות תפריט',
    path: 'settings/menu',
    icon: <IconAdjustments size={24} stroke={1.5} />,
    menuRequired: true,
  },
];

const businessTabs: TabItem[] = [
  {
    label: 'סטטיסטיקות',
    path: '',
    icon: <IconChartAreaLine size={24} stroke={1.5} />,
    businessRequired: true,
  },
  {
    label: 'הגדרות עסק',
    path: 'settings/business',
    icon: <IconSettings size={24} stroke={1.5} />,
    businessRequired: true,
  },
  { label: 'יצירת עסק חדש', path: 'create/business', icon: <IconPlus size={24} stroke={1.5} /> },
];

interface AppNavbarProps {
  isNavbarOpened: boolean;
  toggleNavbar: () => void;
}

export const AppNavbar: React.FC<AppNavbarProps> = ({
  isNavbarOpened,
  toggleNavbar,
}: AppNavbarProps) => {
  const theme = useMantineTheme();
  const { selectedBusiness } = useBusinesses();
  const { selectedMenu } = useMenu();
  const location = useLocation();

  return (
    <Navbar
      sx={{ flex: 1, zIndex: 10 }}
      p='md'
      hiddenBreakpoint='sm'
      hidden={!isNavbarOpened}
      width={{ sm: 200, lg: 300 }}
    >
      {selectedBusiness && (
        <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
          <Text mb='md' size='xl' align='center' color={theme.colors.orange[5]}>
            {selectedBusiness.name}
          </Text>
        </MediaQuery>
      )}
      {!isEmpty(selectedBusiness) && (
        <>
          <MenuSelector closeNavbar={toggleNavbar} />
          {menuTabs.map((tab) =>
            (tab.menuRequired && isEmpty(selectedMenu)) || isEmpty(selectedBusiness) ? null : (
              <NavLink
                component={Link}
                to={tab.path}
                key={tab.label}
                label={tab.label}
                icon={tab.icon}
                onClick={toggleNavbar}
                active={location.pathname === `/${tab.path}`}
              />
            ),
          )}
          {selectedBusiness.active ? <Divider /> : null}
        </>
      )}
      {businessTabs.map((tab) =>
        tab.businessRequired && isEmpty(selectedBusiness) ? null : (
          <NavLink
            component={Link}
            to={tab.path}
            key={tab.label}
            label={tab.label}
            icon={tab.icon}
            onClick={toggleNavbar}
            active={location.pathname === `/${tab.path}`}
          />
        ),
      )}
    </Navbar>
  );
};
