import { Box, Button, Flex, Space, Text, useMantineTheme } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck, IconPlus, IconX } from '@tabler/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { find, isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { businessesApi } from '~/api/businessesApi';
import { menusApi } from '~/api/menusApi';
import DashboardHeader from '~/components/DashboardHeader';
import { useBusinesses } from '~/contexts/Businesses';
import { useMenu } from '~/contexts/Menu';
import CreateMenuForm from '~/forms/CreateMenuForm';
import { FormState } from '~/forms/formState';
import { Menu } from '~/models/Menu';

type CreateMenuProps = {
  onCreate?: (menuSlug: string) => void;
};

export const CreateMenu: React.FC<CreateMenuProps> = ({ onCreate }: CreateMenuProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { selectedBusiness, selectBusinessById } = useBusinesses();
  const { selectMenu } = useMenu();
  const [menu, setMenu] = useState<Pick<Menu, 'slug' | 'displayName'> | null>(null);
  const [menuFormState, setMenuFormState] = useState<FormState | null>(null);

  const { data: menus } = useQuery(['fetchExistedMenus', selectedBusiness], {
    queryFn: () => {
      return menusApi.fetchMenus(selectedBusiness!.id);
    },
    enabled: !!selectedBusiness,
    initialData: [],
  });

  const mutation = useMutation({
    mutationFn: async () => {
      if (!menu) {
        throw 'שגיאה!';
      }

      if (find(menus, { slug: menu.slug })) {
        showNotification({
          id: 'create-menu',
          color: 'red',
          title: 'הסלאג כבר קיים ברשימת התפריטים!',
          message: '',
          icon: <IconX size={18} />,
          autoClose: 5000,
        });
        throw 'שגיאה!';
      }

      if (selectedBusiness && !find(menus, { slug: menu.slug })) {
        showNotification({
          id: 'create-menu',
          title: 'יוצר תפריט',
          message: 'המערכת מייצרת את התפריט...',
          loading: true,
          autoClose: false,
          disallowClose: true,
        });
        return await menusApi.createNewMenu(selectedBusiness.id, menu);
      }
      throw 'שגיאה!';
    },
    onSuccess: async () => {
      if (selectedBusiness && isEmpty(selectedBusiness.defaultMenu)) {
        await businessesApi.updateDefaultMenu(selectedBusiness.id, menu!.slug);
      }

      queryClient.invalidateQueries({ queryKey: ['fetchMenus', selectedBusiness], exact: true });
      if (selectedBusiness) {
        selectBusinessById(selectedBusiness.id);
      }

      updateNotification({
        id: 'create-menu',
        color: 'green',
        title: 'התפריט נוצר בהצלחה!',
        message: '',
        icon: <IconCheck size={18} />,
        autoClose: 5000,
      });
      if (onCreate) {
        onCreate(menu!.slug);
      }
    },
    onError: () => {
      showNotification({
        id: 'create-menu',
        color: 'red',
        title: 'לא ניתן ליצור את התפריט',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
    },
  });

  const handleSelectMenu = () => {
    if (mutation.data) {
      selectMenu(mutation.data);
      navigate('/edit');
    }
  };

  return selectedBusiness ? (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DashboardHeader title='יצירת תפריט' icon={IconPlus} />
      {!mutation.isSuccess ? (
        <Flex direction='column' align='flex-start'>
          <Box maw='300px'>
            <CreateMenuForm
              selectedBusiness={selectedBusiness}
              updateMenu={(values) => setMenu(values!)}
              setFormState={setMenuFormState}
            />
          </Box>
          <Space h='md' />
          <Button
            disabled={
              (menuFormState && !menuFormState?.isDirty) ||
              !menuFormState?.isValid ||
              !isEmpty(menuFormState?.errors)
            }
            loading={mutation.isLoading}
            onClick={() => mutation.mutate()}
            sx={{ alignSelf: 'end' }}
          >
            סיום
          </Button>
        </Flex>
      ) : (
        <Flex direction='column' align='center' justify='center' mt='xl'>
          <Flex
            align='center'
            justify='center'
            sx={{
              width: 150,
              height: 150,
              borderRadius: '50%',
              backgroundColor: theme.colors.green[2],
            }}
          >
            <IconCheck size={150} color='green' />
          </Flex>
          <Space h='xs' />
          <Text size='xl'>התפריט נוצר בהצלחה</Text>
          <Space h='sm' />
          {onCreate ? null : (
            <Button onClick={handleSelectMenu} leftIcon={<IconPlus size={18} />}>
              לעריכת התפריט
            </Button>
          )}
        </Flex>
      )}
    </Box>
  ) : null;
};
