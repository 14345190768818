import { Button, Flex, Space, Text, TextInput } from '@mantine/core';
import { IconPhone, IconUser, IconUsers } from '@tabler/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from '@mantine/hooks';
import { RegisterProvidersFormModel } from './interfaces';
import { first, last } from 'lodash';

interface RegisterProvidersFormProps {
  defaultName?: string;
  isError: boolean;
  isLoading: boolean;
  submitForm: (registerFormModel: RegisterProvidersFormModel) => void;
}

export const RegisterProvidersForm: React.FC<RegisterProvidersFormProps> = ({
  defaultName,
  submitForm,
  isError,
  isLoading,
}: RegisterProvidersFormProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<RegisterProvidersFormModel>({
    defaultValues: {
      firstName: first(defaultName?.split(' ')) ?? '',
      lastName: last(defaultName?.split(' ')) ?? '',
      phone: '',
    },
  });

  return (
    <Flex direction={isMobile ? 'column' : 'row'}>
      <Flex
        direction='column'
        style={{
          flex: 1,
        }}
      >
        <Flex>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
              validate: (value: string) => (value.trim().length > 1 ? true : 'שם לא תקין'),
            }}
            render={({ field }) => (
              <TextInput
                label='שם פרטי'
                placeholder='שם פרטי'
                icon={<IconUser size={14} />}
                required
                error={errors.firstName?.message}
                {...field}
              />
            )}
          />
          <Space w='xs' />
          <Controller
            name='lastName'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
              validate: (value: string) => (value.trim().length > 1 ? true : 'שם משפחה לא תקין'),
            }}
            render={({ field }) => (
              <TextInput
                label='שם משפחה'
                placeholder='שם משפחה'
                icon={<IconUsers size={14} />}
                error={errors.lastName?.message}
                required
                {...field}
              />
            )}
          />
        </Flex>

        <Controller
          name='phone'
          control={control}
          rules={{
            required: 'שדה זה הוא חובה',
            validate: (value: string) => (value.trim().length > 7 ? true : 'טלפון לא תקין'),
          }}
          render={({ field }) => (
            <TextInput
              label='טלפון'
              placeholder='טלפון'
              icon={<IconPhone size={14} />}
              error={errors.phone?.message}
              required
              {...field}
            />
          )}
        />
        <Text hidden={!isError} size='sm' color='red'>
          לא ניתן להירשם למערכת
        </Text>
        <Button
          loading={isLoading}
          onClick={() => submitForm(getValues())}
          type='submit'
          color='orange'
          my='sm'
          p={0}
          variant='outline'
        >
          המשך
        </Button>
      </Flex>
    </Flex>
  );
};
