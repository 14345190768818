import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Switch, TextInput } from '@mantine/core';
import { IconPinned } from '@tabler/icons';
import React, { useEffect, useRef } from 'react';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { defaultConfig, MenuConfig } from '~/models/Menu';
import { menuConfigValidations } from './validations';
import * as yup from 'yup';
import { FormState } from '~/forms/formState';

const schema: yup.AnyObjectSchema = yup
  .object()
  .shape(menuConfigValidations, [['notice', 'notice']])
  .required();

interface MenuConfigFormProps {
  menuId: string;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  dispatchMenuConfig: React.Dispatch<any>;
  defaultData: MenuConfig;
  setFormState: React.Dispatch<React.SetStateAction<FormState | null>>;
}

export const MenuConfigForm: React.FC<MenuConfigFormProps> = ({
  menuId,
  dispatchMenuConfig,
  defaultData,
  setFormState,
}: MenuConfigFormProps) => {
  const { register, watch, reset, control } = useForm<MenuConfig>({
    defaultValues: { ...defaultConfig, ...defaultData },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const menuIdRef = useRef<string>('');

  const { errors, isValid, isDirty, isValidating } = useFormState({ control });

  useEffect(() => {
    const subscription = watch((data) => {
      dispatchMenuConfig({
        type: 'update',
        updated: data as MenuConfig,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setFormState({ errors, isDirty, isValid });
  }, [errors, isValid, isDirty, isValidating]);

  useEffect(() => {
    if (defaultData && menuId !== menuIdRef.current) {
      reset(defaultData);
      menuIdRef.current = menuId;
    }

    () => {
      menuIdRef.current = '';
    };
  }, [defaultData]);

  return (
    <Box>
      <TextInput
        placeholder='התראה'
        label='התראה'
        icon={<IconPinned />}
        error={errors.notice?.message}
        {...register('notice')}
      />

      <Controller
        name='showSearch'
        control={control}
        render={({ field }) => (
          <Switch
            my='sm'
            label='הצג שורת חיפוש'
            checked={field.value}
            onChange={(event) => field.onChange(event.currentTarget.checked)}
          />
        )}
      />

      <Controller
        name='showFilters'
        control={control}
        render={({ field }) => (
          <Switch
            my='sm'
            label='הצג סינון פריטים'
            checked={field.value}
            onChange={(event) => field.onChange(event.currentTarget.checked)}
          />
        )}
      />
    </Box>
  );
};
