import validator from 'validator';
import * as yup from 'yup';
import { SocialType } from '~/models/Business';
import { validationsMessages } from '../validationsMessages';
import { BusinessFormModel } from './interfaces';

export const businessValidations: Record<keyof BusinessFormModel, any> = {
  name: yup
    .string()
    .min(2, validationsMessages.minLength(2))
    .max(40, validationsMessages.maxLength(40))
    .required(validationsMessages.required()),
  address: yup
    .string()
    .min(2, validationsMessages.minLength(2))
    .max(50, validationsMessages.maxLength(50))
    .required(validationsMessages.required()),
  phone: yup
    .string()
    .required(validationsMessages.required())
    .test('isPhone', validationsMessages.invalidPhone(), (value: string | undefined) =>
      validator.isMobilePhone(value ?? ''),
    ),
  whatsapp: yup
    .string()
    .notRequired()
    .when('whatsapp', {
      is: (value: string) => value?.length,
      then: yup
        .string()
        .min(5, validationsMessages.minLength(5))
        .max(15, validationsMessages.maxLength(15)),
    }),
  slug: yup
    .string()
    .min(3, validationsMessages.minLength(3))
    .max(25, validationsMessages.maxLength(25))
    .required(validationsMessages.required())
    .test('isSlug', validationsMessages.invalidSlug(), (value: string | undefined) =>
      validator.isSlug(value ?? ''),
    ),
  defaultMenu: yup.string().notRequired(),
  kosher: yup.string().required(validationsMessages.required()),
  social: yup.object().shape(
    {
      facebook: yup
        .string()
        .notRequired()
        .when('facebook', {
          is: (value: string) => value?.length,
          then: yup.string().required().url(validationsMessages.invalidURL()),
        }),
      twitter: yup
        .string()
        .notRequired()
        .when('twitter', {
          is: (value: string) => value?.length,
          then: yup.string().required().url(validationsMessages.invalidURL()),
        }),
      instagram: yup
        .string()
        .notRequired()
        .when('instagram', {
          is: (value: string) => value?.length,
          then: yup.string().required().url(validationsMessages.invalidURL()),
        }),
    } as Record<SocialType, any>,
    [
      ['facebook', 'facebook'],
      ['twitter', 'twitter'],
      ['instagram', 'instagram'],
    ],
  ),
  network_name: yup
    .string()
    .notRequired()
    .when('network_name', {
      is: (value: string) => value?.length,
      then: yup
        .string()
        .required(validationsMessages.required())
        .min(2, validationsMessages.minLength(2))
        .max(40, validationsMessages.maxLength(40)),
    })
    .when('network_password', {
      is: (network_password: string) => network_password.trim().length > 0,
      then: yup
        .string()
        .required(validationsMessages.requiredDependency('סיסמה', 'שם רשת'))
        .min(2, validationsMessages.minLength(2))
        .max(40, validationsMessages.maxLength(40)),
    }),
  network_password: yup
    .string()
    .notRequired()
    .when('network_password', {
      is: (value: string) => value?.length,
      then: yup
        .string()
        .required(validationsMessages.required())
        .min(1, validationsMessages.minLength(1))
        .max(50, validationsMessages.maxLength(50)),
    }),
  logo: undefined,
};
