import { Box, Button, Flex, Space } from '@mantine/core';
import React, { useMemo, useRef } from 'react';
import QRCode from 'qrcode';
import { FileWithPath } from '@mantine/dropzone';
import { isString } from 'lodash';
import { IconDownload } from '@tabler/icons';
import config from '~/config';
import { downloadFile } from '~/utils/downloadFile';
import isURL from 'validator/lib/isURL';

interface ViewQRCodeProps {
  slug: string | null;
  logo?: string | File | FileWithPath | null;
}

export const ViewQRCode: React.FC<ViewQRCodeProps> = ({ slug, logo }: ViewQRCodeProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const getCurrentLogo = useMemo(async () => {
    let currentLogo = '/images/logo.png';
    if (logo) {
      if (isString(logo)) {
        currentLogo = isURL(logo) ? logo : `${config.imageBaseURL}/${logo}/public`;
      } else {
        currentLogo = URL.createObjectURL(logo);
      }
    }
    return currentLogo;
  }, [logo]);

  useMemo(async () => {
    const currentLogo = await getCurrentLogo;
    const canvasSize = 300;

    const imgDim = canvasSize * 0.3;
    const context = canvasRef.current?.getContext('2d');
    const imageObj = new Image();
    imageObj.src = currentLogo;
    imageObj.crossOrigin = 'anonymous';
    imageObj.onload = () => {
      if (context) {
        const imageCenterPosition = canvasSize / 2 - imgDim / 2;
        context.fillStyle = '#ffffff';
        context.beginPath();
        context.arc(canvasSize / 2, canvasSize / 2, imgDim / 2, 0, 2 * Math.PI);
        context.fill();
        context.clip();
        context.drawImage(imageObj, imageCenterPosition, imageCenterPosition, imgDim, imgDim);
      }
    };

    return await QRCode.toCanvas(canvasRef.current, `${config.menuBaseURL}/${slug}`, {
      margin: 0.5,
      width: canvasSize,
      errorCorrectionLevel: 'H',
      color: { light: '#ffffff', dark: '#000000' },
    });
  }, [canvasRef, slug, logo, getCurrentLogo]);

  const downloadQRCode = async () => {
    downloadFile(canvasRef.current!.toDataURL('image/png'), 'QRCode.png');
  };

  return (
    <Flex my='sm' direction='column' align='flex-start' justify='center'>
      <Flex direction='column' align='center'>
        <Box sx={{ width: '100%', height: '100%' }}>
          <canvas ref={canvasRef} />
        </Box>
        <Space h='xs' />
        <Button leftIcon={<IconDownload />} onClick={downloadQRCode}>
          הורדה
        </Button>
      </Flex>
    </Flex>
  );
};
