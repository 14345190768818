import {
  ActionIcon,
  AspectRatio,
  Box,
  Center,
  Flex,
  Image,
  LoadingOverlay,
  Overlay,
  Paper,
  Text,
  Tooltip,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import { IconPhotoOff, IconPhotoUp } from '@tabler/icons';
import { isEqual, isString } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isURL from 'validator/lib/isURL';
import config from '~/config';
import { FileUploadModal } from './FileUploadModal/FileUploadModal';

export type ImageTypes = string | FileWithPath | File | null | undefined;

interface FileUploadProps {
  uploadImage: (file: File | FileWithPath | string | null) => void;
  defaultImage: ImageTypes;
}

export const FileUpload: React.FC<FileUploadProps> = memo(
  ({ uploadImage, defaultImage }: FileUploadProps) => {
    const [isFileUploadModalOpened, toggleFileUploadModalOpened] = useToggle();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [image, setImage] = useState<ImageTypes>(null);
    const [deleteOverlay, setDeleteOverlay] = useToggle<boolean>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const onUploadImage = useCallback((file: ImageTypes) => {
      if (file) {
        toggleFileUploadModalOpened();
      }
      setImage(file);
      uploadImage(file!);
    }, []);

    const getImageSrc = useMemo(() => {
      return image ? (isString(image) ? image : URL.createObjectURL(image!)) : '';
    }, [image]);

    const onDelete = useCallback(() => {
      onUploadImage(null);
    }, []);

    const getSelectedNodeImage = async () => {
      if (defaultImage) {
        if (isString(defaultImage)) {
          setImage(
            isURL(defaultImage) ? defaultImage : `${config.imageBaseURL}/${defaultImage}/public`,
          );
        } else {
          if (!isEqual(image, defaultImage)) {
            setImage(defaultImage);
          }
        }
      } else {
        setImage(null);
      }
      setLoading(false);
    };

    useEffect(() => {
      if (defaultImage) {
        if (image !== defaultImage) {
          setLoading(true);
        }
        getSelectedNodeImage();
      } else {
        setImage(null);
      }
    }, [defaultImage]);

    return (
      <Flex
        direction={isMobile ? 'column' : 'row'}
        sx={{
          maxWidth: 500,
          maxHeight: 300,
        }}
      >
        <FileUploadModal
          isOpened={isFileUploadModalOpened}
          onClose={() => toggleFileUploadModalOpened()}
          onUploadImage={onUploadImage}
        />
        {!image ? (
          <Flex direction='column' sx={{ flex: 1 }}>
            <Paper
              onClick={() => toggleFileUploadModalOpened()}
              shadow='md'
              radius='sm'
              p='xl'
              sx={{ flex: 1, borderStyle: 'dashed', borderWidth: '2px', borderColor: '#ced4da' }}
            >
              <Flex
                direction='column'
                align='center'
                justify='center'
                sx={{ cursor: 'pointer' }}
                p='xl'
              >
                <IconPhotoUp size={50} stroke={1.5} />
                <Text mt='xl' size='xl' inline>
                  העלאת תמונה
                </Text>
              </Flex>
            </Paper>
          </Flex>
        ) : (
          <Flex direction='column' sx={{ flex: 1 }}>
            {image ? (
              <AspectRatio
                onMouseEnter={() => setDeleteOverlay(true)}
                onMouseLeave={() => setDeleteOverlay(false)}
                ratio={16 / 9}
              >
                {deleteOverlay && (
                  <Box sx={{ position: 'relative' }}>
                    <Overlay opacity={0.6} zIndex={5} color='#000' blur={5} />
                    <Flex
                      justify='center'
                      align='center'
                      sx={{
                        zIndex: 10,
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <Tooltip label='מחק תמונה'>
                        <ActionIcon
                          onClick={() => onDelete()}
                          color='white'
                          variant='transparent'
                          size='xl'
                          mx='sm'
                        >
                          <IconPhotoOff size={32} color='white' />
                        </ActionIcon>
                      </Tooltip>
                    </Flex>
                  </Box>
                )}
                {isLoading ? (
                  <LoadingOverlay visible={true} />
                ) : (
                  <Image src={getImageSrc} fit='cover' withPlaceholder />
                )}
              </AspectRatio>
            ) : (
              <Center sx={{ flex: 1, border: `1px solid #ced4da`, borderRadius: 4 }}>
                <Image fit='cover' withPlaceholder />
              </Center>
            )}
            <Text size='sm' color='dimmed'>
              העבר את העכבר מעל התמונה על מנת להסיר / להחליף אותה
            </Text>
          </Flex>
        )}
      </Flex>
    );
  },
);
