import { Button } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons';
import { useMutation } from '@tanstack/react-query';
import { useMenu } from '~/contexts/Menu';
import { MenuSettingsFormModel } from '~/forms/MenuSettingsForm/interfaces';
import { MenuConfig, MenuData } from '~/models/Menu';
import { Theme } from '~/models/Theme';

interface SaveMenuButtonProps {
  updatedData: { [type: string]: MenuData | Theme | MenuConfig | MenuSettingsFormModel };
  isValid: boolean;
  requestedMenuSlug?: string;
}

export const SaveMenuButton: React.FC<SaveMenuButtonProps> = ({
  updatedData,
  isValid,
  requestedMenuSlug,
}: SaveMenuButtonProps) => {
  const { saveMenu } = useMenu();

  const mutation = useMutation({
    mutationFn: async () => {
      return await saveMenu(updatedData, requestedMenuSlug);
    },
  });

  return (
    <Button
      disabled={!isValid}
      onClick={() => mutation.mutate()}
      loading={mutation.isLoading}
      leftIcon={<IconDeviceFloppy />}
    >
      שמור שינויים
    </Button>
  );
};
