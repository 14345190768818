import React, { useContext, useEffect, useState } from 'react';
import { EditableMenuNode } from '~/models/Menu';
import { menuNodesUtils } from '~/utils/menuNodesUtils';
import { useMenu } from './Menu';

const MenuNodeEditContext = React.createContext<{
  selectedNode: EditableMenuNode | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<EditableMenuNode | null>>;
}>({
  selectedNode: null,
  setSelectedNode: () => undefined,
});

export const MenuNodeEditProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedNode, setSelectedNode] = useState<EditableMenuNode | null>(null);
  const { selectedMenu } = useMenu();

  useEffect(() => {
    if (selectedNode && selectedMenu) {
      const foundNode = menuNodesUtils.findNode(selectedMenu.data, selectedNode.id);
      setSelectedNode(foundNode || null);
    } else {
      setSelectedNode(null);
    }
  }, [selectedMenu]);

  const value = {
    selectedNode,
    setSelectedNode,
  };

  return <MenuNodeEditContext.Provider value={value}>{children}</MenuNodeEditContext.Provider>;
};

export function useMenuNodeEdit() {
  return useContext(MenuNodeEditContext);
}
