import { Flex, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { IconAlertCircle, IconGripVertical } from '@tabler/icons';
import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { EditableMenuNode, MenuNode } from '~/models/Menu';

interface CategoryContainerProps {
  category: MenuNode;
  nodesValidations: {
    valid: boolean;
    errors: any;
  };
  setSelectedNode: React.Dispatch<React.SetStateAction<EditableMenuNode | null>>;
}

export const CategoryContainer: React.FC<CategoryContainerProps> = memo(
  ({ category, nodesValidations, setSelectedNode }: CategoryContainerProps) => {
    const theme = useMantineTheme();

    return (
      <Flex align='center' sx={{ flex: 1 }} py='xs' onClick={() => setSelectedNode(category)}>
        <IconGripVertical style={{ cursor: 'grab' }} />
        <Tooltip label={category.title} withArrow transition='fade' transitionDuration={200}>
          <Text
            px='xs'
            color={theme.colors.orange[5]}
            sx={{
              maxWidth: 200,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {category.title}
          </Text>
        </Tooltip>
        {nodesValidations && !nodesValidations.valid && !isEmpty(nodesValidations.errors) ? (
          <IconAlertCircle color='red' />
        ) : null}
      </Flex>
    );
  },
);
