import { Palette } from '~/models/Palette';
import { find, fromPairs, isEqual, pick, toPairs } from 'lodash';

export const palettesUtils = {
  findPaletteByColors: (palettes: Palette[], colors: Omit<Palette, 'id'>) => {
    return find(palettes, (palette: Palette) => {
      const menuColors = fromPairs(
        toPairs(colors).map(([key, value]) => [key, (value as string).toLowerCase()]),
      );

      const formattedPalette = fromPairs(
        toPairs(pick(palette, ['backgroundColor', 'primaryColor', 'secondaryColor'])).map(
          ([key, value]) => [key, (value as string).toLowerCase()],
        ),
      );

      return isEqual(formattedPalette, menuColors);
    });
  },
};
