import { Box, Flex, ScrollArea } from '@mantine/core';
import { IconWand } from '@tabler/icons';
import DashboardHeader from '~/components/DashboardHeader';
import { useMenu } from '~/contexts/Menu';
import { useCallback, useEffect, useReducer, useState } from 'react';
import MenuDesignForm from '~/forms/MenuDesignForm';
import { Theme } from '~/models/Theme';
import SaveMenuButton from '../../components/SaveMenuButton';
import Loading from '~/components/Loading';
import { isEmpty, isEqual } from 'lodash';
import Palettes from './Palettes';
import { Palette } from '~/models/Palette';
import { FormState } from '~/forms/formState';

const themeReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'update': {
      return { ...state, ...action.updated };
    }
    case 'init':
      return action.data;
    default:
      return state;
  }
};

export const Design = () => {
  const { selectedMenu } = useMenu();
  const [menuTheme, dispatchMenuTheme] = useReducer(themeReducer, selectedMenu?.theme);
  const [formState, setFormState] = useState<FormState | null>(null);
  const [selectedPalette, setSelectedPalette] = useState<Palette>();
  const [initialMenuTheme, setInitialMenuTheme] = useState<Theme | null>(null);

  const setColorPalette = useCallback(
    (palette: Palette) => {
      setSelectedPalette(palette);
    },
    [selectedMenu, initialMenuTheme],
  );

  useEffect(() => {
    if (selectedMenu) {
      dispatchMenuTheme({ type: 'init', data: selectedMenu.theme });
      setInitialMenuTheme(selectedMenu.theme);
    }

    () => {
      dispatchMenuTheme({ type: 'init' });
      setInitialMenuTheme(null);
    };
  }, [selectedMenu]);

  return (
    <Flex direction='column' sx={{ flex: 1 }}>
      <Box>
        <DashboardHeader title='עיצוב תפריט' icon={IconWand}>
          <SaveMenuButton
            updatedData={{ theme: menuTheme }}
            isValid={
              !!formState &&
              formState?.isValid &&
              formState?.isDirty &&
              isEmpty(formState?.errors) &&
              !isEqual(initialMenuTheme, menuTheme)
            }
          />
        </DashboardHeader>
      </Box>
      {selectedMenu && menuTheme && initialMenuTheme ? (
        <ScrollArea type='auto' offsetScrollbars={true} pr='xs'>
          <Flex direction='column'>
            <>
              <MenuDesignForm
                menuTheme={menuTheme}
                initialMenuTheme={initialMenuTheme}
                selectedPalette={selectedPalette}
                dispatchMenuTheme={dispatchMenuTheme}
                setFormState={setFormState}
              />
              <Palettes initialMenuTheme={initialMenuTheme} setColorPalette={setColorPalette} />
            </>
          </Flex>
        </ScrollArea>
      ) : (
        <Loading text='טוען עיצוב תפריט..' />
      )}
    </Flex>
  );
};
