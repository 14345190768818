import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Space, Text, TextInput, Tooltip } from '@mantine/core';
import { IconMenu2, IconQuestionMark, IconWorld } from '@tabler/icons';
import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Menu } from '~/models/Menu';
import { createMenuValidations } from './validations';
import * as yup from 'yup';
import { FormState } from '~/forms/formState';
import config from '~/config';
import { Business } from '~/models/Business';

const schema: yup.AnyObjectSchema = yup.object().shape(createMenuValidations).required();

interface CreateMenuFormProps {
  selectedBusiness: Business;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  updateMenu: (data: Pick<Menu, 'slug' | 'displayName'>) => void;
  setFormState: React.Dispatch<React.SetStateAction<FormState | null>>;
}

export const CreateMenuForm: React.FC<CreateMenuFormProps> = ({
  selectedBusiness,
  updateMenu,
  setFormState,
}: CreateMenuFormProps) => {
  const { register, watch, control } = useForm<Pick<Menu, 'slug' | 'displayName'>>({
    defaultValues: {
      slug: '',
      displayName: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { errors, isValid, isDirty, isValidating } = useFormState({ control });

  useEffect(() => {
    const subscription = watch((data) => {
      updateMenu(data as Pick<Menu, 'slug' | 'displayName'>);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setFormState({ errors, isDirty, isValid });
  }, [errors, isValid, isDirty, isValidating]);

  return (
    <Box>
      <TextInput
        placeholder='שם התפריט'
        label='שם התפריט'
        icon={<IconMenu2 />}
        error={errors.displayName?.message}
        {...register('displayName')}
      />

      <TextInput
        mt='sm'
        type='text'
        placeholder='סלאג'
        label='סלאג'
        className='ltr-input'
        rightSection={
          <Tooltip
            dir='rtl'
            label={
              <>
                סלאג זה צמד מילים המשמש כקיצור לעסק שלכם. <Space />
                סלאג יכול להכיל רק תווים באנגלית ומקפים.
                <Space />
              </>
            }
            position='top-end'
            withArrow
          >
            <div>
              <IconQuestionMark size={18} style={{ display: 'block', opacity: 0.5 }} />
            </div>
          </Tooltip>
        }
        icon={<IconWorld />}
        withAsterisk
        required
        error={errors.slug?.message}
        {...register('slug')}
      />
      <Text size='xs' color='dimmed'>
        דוגמה: {config.menuBaseURL}/{selectedBusiness.slug}/<b>my-restaurant</b>
      </Text>
      <Space h='xs' />
      <Text dir='ltr'>
        {config.menuBaseURL}/{selectedBusiness.slug}/{watch('slug')}
      </Text>
    </Box>
  );
};
