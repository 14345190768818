import { FileWithPath } from '@mantine/dropzone';

export const imagesApi = {
  uploadImages: async (images: { id: string; data: File | FileWithPath | string }[]) => {
    const imageFormData = new FormData();
    images.map((image) => {
      imageFormData.append(image.id, image.data);
    });

    const uploadResult = await fetch('https://upload.tafryt.co.il', {
      method: 'post',
      body: imageFormData,
    });

    return await uploadResult.json();
  },
};
