import { User } from '@supabase/supabase-js';
import { omit } from 'lodash';
import React, { useEffect, useContext, useState } from 'react';
import { LoginFormModel } from '~/forms/LoginForm/interfaces';
import { RegisterFormModel } from '~/forms/RegisterForm/interfaces';
import { RegisterProvidersFormModel } from '~/forms/RegisterProvidersForm/interfaces';
import { supabase } from '~/supabaseClient';

const AuthContext = React.createContext<{
  signUp: (data: RegisterFormModel) => any;
  updateUser: (data: RegisterProvidersFormModel) => any;
  signIn: (data: LoginFormModel) => any;
  signInGoogle: () => any;
  signInFacebook: () => any;
  signOut: () => any;
  user: User | null | undefined;
}>({
  signUp: () => undefined,
  signIn: () => undefined,
  updateUser: () => undefined,
  signInGoogle: () => undefined,
  signInFacebook: () => undefined,
  signOut: () => undefined,
  user: undefined,
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const session = await supabase.auth.getSession();
      const sessionUser = session?.data?.session?.user ?? null;
      setUser(sessionUser);
      setLoading(false);
    })();

    const { data: listener } = supabase.auth.onAuthStateChange((_, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const value = {
    signUp: (data: RegisterFormModel) =>
      supabase.auth.signUp({
        email: data.email.trim(),
        password: data.password.trim(),
        options: {
          data: omit(data, ['email', 'password']),
        },
      }),
    updateUser: (data: RegisterProvidersFormModel) =>
      supabase.auth.updateUser({ data: { ...user?.user_metadata, ...data } }),
    signIn: (data: LoginFormModel) => supabase.auth.signInWithPassword(data),
    signInGoogle: () => supabase.auth.signInWithOAuth({ provider: 'google' }),
    signInFacebook: () => supabase.auth.signInWithOAuth({ provider: 'facebook' }),
    signOut: () => supabase.auth.signOut(),
    user,
  };

  return <AuthContext.Provider value={value}>{!isLoading && children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
