import { useRef } from 'react';
import { useProfile } from '~/contexts/Profile';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useMenu } from '~/contexts/Menu';
import { useBusinesses } from '~/contexts/Businesses';

export const Chat = () => {
  const tawkMessengerRef = useRef();
  const { profile } = useProfile();
  const { selectedMenu } = useMenu();
  const { selectedBusiness } = useBusinesses();

  const loadProfile = () => {
    if (tawkMessengerRef.current && profile) {
      (tawkMessengerRef.current as any).setAttributes({
        userID: profile.id,
        name: `${profile.firstName} ${profile.lastName}`,
        email: profile.email,
        phone: profile.phone,
        businessID: selectedBusiness?.id ?? '',
        menuID: selectedMenu?.id ?? '',
      });
    }
  };

  return (
    <TawkMessengerReact
      propertyId='6385c49eb0d6371309d1aa07'
      widgetId='1gpvn38tf'
      onLoad={loadProfile}
      ref={tawkMessengerRef}
    />
  );
};
