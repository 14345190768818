export type MenuKosherKeys = 'none' | 'kosher' | 'lamehadrin' | 'badatz';

export type SocialType = 'instagram' | 'facebook' | 'twitter';

export const availableMenuKoshers: { value: MenuKosherKeys; label: string }[] = [
  { value: 'none', label: 'לא כשר' },
  { value: 'kosher', label: 'כשר' },
  { value: 'lamehadrin', label: 'כשרות מהדרין' },
  { value: 'badatz', label: 'כשרות בד"צ' },
];

export interface Business {
  id: string;
  userId: string;
  name: string;
  slug: string;
  phone: string;
  address: string;
  kosher: MenuKosherKeys;
  whatsapp: string;
  network_name: string;
  network_password: string;
  social: Record<SocialType, string>;
  defaultMenu: string;
  logo?: string;
  active?: boolean;
  is_paying?: boolean;
  expiration_date?: Date;
}
