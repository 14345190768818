import { ColorInput } from '@mantine/core';
import React, { ReactNode } from 'react';

interface ColorSelectorProps {
  label: string;
  error: ReactNode;
  placeholder?: string;
  props?: any;
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({
  props,
  label,
  placeholder,
  error,
}: ColorSelectorProps) => {
  return (
    <ColorInput
      label={label}
      sx={{ maxWidth: 'fit-content' }}
      placeholder={placeholder}
      format='hex'
      error={error}
      swatches={[
        '#25262b',
        '#868e96',
        '#fa5252',
        '#e64980',
        '#be4bdb',
        '#7950f2',
        '#4c6ef5',
        '#228be6',
        '#15aabf',
        '#12b886',
        '#40c057',
        '#82c91e',
        '#fab005',
        '#fd7e14',
      ]}
      {...props}
    />
  );
};
