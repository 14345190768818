import { createEmotionCache, MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationsProvider } from '@mantine/notifications';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import rtlPlugin from 'stylis-plugin-rtl';
import { renderChildrens, routes } from './routes';
import { AuthProvider } from '~/contexts/Auth';
import { ModalsProvider } from '@mantine/modals';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/he';
import ReactGA from 'react-ga4';

dayjs.locale('he');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekday);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const rtlCache = createEmotionCache({
  key: 'mantine-rtl',
  stylisPlugins: [rtlPlugin],
});

ReactGA.initialize([
  {
    trackingId: 'G-2J4G6J3E52',
  },
]);
ReactGA.send({ hitType: 'pageview', page: '/' });

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionCache={rtlCache}
        theme={{
          dir: 'rtl',
          fontFamily: 'Open Sans, sans-serif',
          colors: {
            slate: [
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#FFFFFF',
              '#EDEEF5',
              '#C9CCE1',
              '#A5AACD',
              '#8188B9',
              '#5D66A5',
            ],
            black: [
              '#837C80',
              '#797276',
              '#645F61',
              '#4F4B4D',
              '#3A3738',
              '#252324',
              '#080808',
              '#000000',
              '#000000',
              '#000000',
            ],
            green: [
              '#F5FBF7',
              '#E6F5EB',
              '#C8EAD4',
              '#ABDFBD',
              '#8DD3A5',
              '#70C88E',
              '#47B86E',
              '#389056',
              '#28683E',
              '#183F26',
            ],
            orange: [
              '#FEF8F0',
              '#FDEFDD',
              '#FCDCB6',
              '#FACA8F',
              '#F8B768',
              '#F6A541',
              '#F38C0C',
              '#BE6D09',
              '#884E06',
              '#532F04',
            ],
            tafrytGray: [
              '#FFFFFF',
              '#E8E9EE',
              '#E8E9EE',
              '#E8E9EE',
              '#E8E9EE',
              '#D1D2DD',
              '#B1B2C5',
              '#9093AD',
              '#707395',
              '#575A76',
            ],
          },
          primaryColor: 'orange',
        }}
      >
        <AuthProvider>
          <ModalsProvider>
            <NotificationsProvider>
              <Router>
                <Routes>
                  {routes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.component}>
                      {route.children ? renderChildrens(route.children) : null}
                    </Route>
                  ))}
                </Routes>
              </Router>
            </NotificationsProvider>
          </ModalsProvider>
        </AuthProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
