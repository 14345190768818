import {
  Burger,
  Divider,
  Flex,
  Header,
  MediaQuery,
  Space,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useBusinesses } from '~/contexts/Businesses';
import { useProfile } from '~/contexts/Profile';
import UserMenu from './components/UserMenu';

interface AppHeaderProps {
  isNavbarOpened: boolean;
  toggleNavbar: () => void;
}

export const AppHeader: React.FC<AppHeaderProps> = ({
  isNavbarOpened,
  toggleNavbar,
}: AppHeaderProps) => {
  const theme = useMantineTheme();
  const { profile } = useProfile();
  const { selectedBusiness } = useBusinesses();

  return (
    <Header height={70} p='md' sx={{ color: theme.white, backgroundColor: theme.colors.orange[5] }}>
      <Flex
        justify='space-between'
        align='center'
        style={{
          height: '100%',
        }}
      >
        <Flex align='center'>
          <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
            <Burger
              opened={isNavbarOpened}
              onClick={toggleNavbar}
              size='sm'
              color='white'
              mr='xs'
            />
          </MediaQuery>
          <Text component={Link} to='/' mx='xl' size='xl'>
            Tafryt
          </Text>
          {selectedBusiness && (
            <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
              <Flex>
                <Divider orientation='vertical' size='sm' />
                <Text mx='xl' size='md'>
                  {selectedBusiness.name}
                </Text>
              </Flex>
            </MediaQuery>
          )}
        </Flex>
        <Flex align='center'>
          {profile && <Text>שלום, {profile.firstName}</Text>}
          <Space w='xs' />
          <UserMenu />
        </Flex>
      </Flex>
    </Header>
  );
};
