import { Flex, Loader, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

interface LoadingProps {
  text: string | React.ReactNode;
  height?: string;
}

export const Loading: React.FC<LoadingProps> = ({ text, height }: LoadingProps) => {
  const theme = useMantineTheme();

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      sx={{
        height: height ?? '100%',
      }}
    >
      <Loader variant='oval' />
      <Text color={theme.colors.orange[9]}>{text}</Text>
    </Flex>
  );
};
