import {
  Anchor,
  Button,
  FileButton,
  Flex,
  Group,
  Modal,
  Paper,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useInputState } from '@mantine/hooks';
import { IconLink, IconPhoto, IconUpload, IconX } from '@tabler/icons';
import React, { useCallback, useEffect, useRef } from 'react';
import { ImageTypes } from '../FileUpload';
import { first, isEmpty, last } from 'lodash';

interface FileUploadModalProps {
  isOpened: boolean;
  onClose: () => void;
  onUploadImage: (file: ImageTypes) => void;
}

export const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpened,
  onClose,
  onUploadImage,
}: FileUploadModalProps) => {
  const theme = useMantineTheme();
  const [externalImage, setExternalImage] = useInputState<string>('');
  const openFileExplorerRef = useRef<() => void>(() => {
    return;
  });

  const onDrop = useCallback((files: FileWithPath[]) => {
    if (!isEmpty(files)) {
      onUploadImage(first(files)!);
    }
  }, []);

  const onFileUpload = useCallback((file: File) => {
    if (file) {
      onUploadImage(file);
    }
  }, []);

  useEffect(() => {
    setExternalImage('');
  }, [isOpened]);

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      centered
      size='30%'
      overlayOpacity={0.55}
      overlayBlur={2}
    >
      <Flex direction='column'>
        <Text size={32} sx={{ alignSelf: 'center' }} color={theme.colors.orange[5]}>
          העלת תמונה
        </Text>
        <Flex direction='row' mt='xl' sx={{ flex: 1 }} px='xl'>
          <Dropzone
            sx={{ flex: 1 }}
            openRef={openFileExplorerRef}
            onDrop={onDrop}
            multiple={false}
            maxSize={5 * 1024 ** 10}
            accept={IMAGE_MIME_TYPE}
          >
            <Group position='center' spacing='xl' style={{ minHeight: 220, pointerEvents: 'none' }}>
              <Dropzone.Accept>
                <IconUpload
                  size={50}
                  stroke={1.5}
                  color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size={50}
                  stroke={1.5}
                  color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size={50} stroke={1.5} />
              </Dropzone.Idle>

              <div>
                <Text size='lg' inline>
                  גררו תמונה או לחצו על מנת לבחור תמונה
                </Text>
                <FileButton
                  onChange={onFileUpload}
                  multiple={false}
                  accept='image/png,image/gif,image/jpeg,image/svg+xml,image/webp'
                >
                  {(props) => (
                    <Text size='sm' color='dimmed'>
                      לא מצליחים לעלות תמונה? <Anchor {...props}>לחצו כאן</Anchor>
                    </Text>
                  )}
                </FileButton>
              </div>
            </Group>
          </Dropzone>
          <Paper
            shadow='xs'
            mx='xs'
            radius='sm'
            p='xs'
            sx={{ flex: 1, borderStyle: 'dashed', borderWidth: '2px', borderColor: '#ced4da' }}
          >
            <Flex direction='column' align='center' justify='space-evenly' h='100%'>
              <IconLink size={50} stroke={1.5} />
              <Flex direction='column' align='center'>
                <Text size='lg' inline>
                  העלו באמצעות קישור לתמונה
                </Text>
                <TextInput
                  w='100%'
                  mt='xs'
                  placeholder='קישור'
                  value={externalImage}
                  onChange={setExternalImage}
                />
                <Button
                  disabled={
                    externalImage.trim().length === 0 ||
                    !['png', 'gif', 'jpg', 'jpeg', 'webp'].includes(
                      last(externalImage.split('.')) ?? '',
                    )
                  }
                  mt='xs'
                  onClick={() => onUploadImage(externalImage)}
                >
                  העלה
                </Button>
              </Flex>
            </Flex>
          </Paper>
        </Flex>
      </Flex>
    </Modal>
  );
};
