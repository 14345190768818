import { Footer } from '@mantine/core';
import { useBusinesses } from '~/contexts/Businesses';
import ActivateBusiness from '../../pages/BusinessSettings/components/ActivateBusiness';
import { useLocation } from 'react-router-dom';

export const AppFooter = () => {
  const { selectedBusiness } = useBusinesses();
  const location = useLocation();

  return location.pathname !== '/create/business' &&
    selectedBusiness &&
    (!selectedBusiness?.active || !selectedBusiness?.is_paying) ? (
    <Footer height={90} fixed={false}>
      <ActivateBusiness />
    </Footer>
  ) : null;
};
