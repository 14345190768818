import { Box, Divider, Flex, List, Paper, Space, useMantineTheme } from '@mantine/core';
import { IconAlertCircle, IconPlus } from '@tabler/icons';
import { css, StyleSheet } from 'aphrodite';
import { get, isEmpty } from 'lodash';
import React, { memo } from 'react';
import { flash } from 'react-animations';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import IconButton from '~/components/IconButton';
import { useMenuNodeEdit } from '~/contexts/MenuNodeEdit';
import { MenuNode } from '~/models/Menu';
import CreateNewNodeButton from '../CreateNewNodeButton';
import ItemNode from '../ItemNode';
import CategoryContainer from './components/CategoryContainer';

const styles = StyleSheet.create({
  flash: {
    animationName: flash,
    animationDuration: '1s',
    animationDelay: 500,
  },
});

const getListStyle = (isDraggingOver: boolean, backgroundColor?: string) => ({
  background: isDraggingOver ? backgroundColor ?? 'lightblue' : '',
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

interface CategoryNodeProps {
  nodeCategory: MenuNode;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  newItemRef: React.MutableRefObject<HTMLDivElement>;
  newItem: {
    type: 'addCategory' | 'addNode';
    id: string;
  } | null;
  nodesValidations: Record<
    string,
    {
      valid: boolean;
      errors: any;
    }
  >;
  categoryValidations: { valid: boolean; errors: any };
  addNodeItem: (type: 'addCategory' | 'addNode', properties?: Record<string, any>) => void;
}

export const CategoryNode: React.FC<CategoryNodeProps> = memo(
  ({
    nodeCategory: category,
    provided,
    snapshot,
    newItemRef,
    newItem,
    nodesValidations,
    categoryValidations,
    addNodeItem,
  }: CategoryNodeProps) => {
    const theme = useMantineTheme();
    const { selectedNode, setSelectedNode } = useMenuNodeEdit();

    return (
      <Paper
        shadow='sm'
        withBorder
        m='xs'
        p='xs'
        ref={provided.innerRef}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          backgroundColor: selectedNode?.id === category.id ? theme.colors.gray[1] : '',
        }}
        className={newItem?.id === category.id ? css(styles.flash) : ''}
      >
        <List listStyleType='none'>
          <Flex
            ref={newItem?.id === category.id ? newItemRef : undefined}
            justify='space-between'
            align='center'
            sx={{
              cursor: 'pointer',
            }}
          >
            <CategoryContainer
              category={category}
              nodesValidations={nodesValidations[category.id]}
              setSelectedNode={setSelectedNode}
            />
            {categoryValidations &&
            !categoryValidations.valid &&
            !isEmpty(categoryValidations.errors) ? (
              <IconAlertCircle color='red' />
            ) : null}
            <Flex align='center'>
              <IconButton
                callback={() => addNodeItem('addNode', { parentId: category.id })}
                tooltip='הוסף פריט חדש'
                icon={IconPlus}
              />
            </Flex>
          </Flex>
          <Divider m={0} />

          <Droppable key={`${category.id}-droppable`} droppableId={category.id} type='item'>
            {(categoryProvided, categorySnapshot) => (
              <>
                <Box
                  {...categoryProvided.droppableProps}
                  ref={categoryProvided.innerRef}
                  style={getListStyle(categorySnapshot.isDraggingOver, theme.colors.orange[1])}
                >
                  {!isEmpty(get(category, 'children', [])) ? (
                    get(category, 'children', []).map((children: MenuNode, index: number) => (
                      <Draggable key={children.id} draggableId={children.id} index={index}>
                        {(childrenProvided, childrenSnapshot) => (
                          <ItemNode
                            childrenProvided={childrenProvided}
                            childrenSnapshot={childrenSnapshot}
                            isNewItem={newItem?.id === children.id}
                            newItemRef={newItemRef}
                            nodesValidations={nodesValidations[children.id]}
                            isSelectedNode={selectedNode?.id === children.id}
                            setSelectedNode={setSelectedNode}
                            parentId={category.id}
                            key={children.id}
                            nodeChildren={children}
                          />
                        )}
                      </Draggable>
                    ))
                  ) : !categorySnapshot.isDraggingOver ? (
                    <CreateNewNodeButton
                      type='item'
                      text='יצירת פריט חדש'
                      parentId={category.id}
                      addNodeItem={addNodeItem}
                    />
                  ) : (
                    <Space h='xl' />
                  )}
                </Box>
                {categoryProvided.placeholder}
              </>
            )}
          </Droppable>
        </List>
      </Paper>
    );
  },
);
