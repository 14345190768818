import { Flex, Space, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

interface UnsupportedFeatureProps {
  text: string;
}

export const UnsupportedFeature: React.FC<UnsupportedFeatureProps> = ({
  text,
}: UnsupportedFeatureProps) => {
  const theme = useMantineTheme();

  return (
    <Flex direction='column' justify='center' align='center' sx={{ flex: 1 }}>
      <Text size='xl' align='center'>
        {text}
      </Text>
      <Space h='md' />
      <Text align='center' size='lg'>
        עמכם הסליחה,
      </Text>
      <Text size='lg' color={theme.colors.orange[5]}>
        צוות Tafryt
      </Text>
    </Flex>
  );
};
