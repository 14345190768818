import { Button, Flex, Text, TextInput } from '@mantine/core';
import { IconLock, IconMail } from '@tabler/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from '@mantine/hooks';
import { LoginFormModel } from './interfaces';

interface LoginFormProps {
  isError: boolean;
  isLoading: boolean;
  submitForm: (loginFormModel: LoginFormModel) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  submitForm,
  isError,
  isLoading,
}: LoginFormProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { getValues, control } = useForm<LoginFormModel>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <Flex direction={isMobile ? 'column' : 'row'}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm(getValues());
        }}
      >
        <Flex
          direction='column'
          sx={{
            flex: 1,
          }}
        >
          <Controller
            name='email'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
            }}
            render={({ field }) => (
              <TextInput
                label='אימייל'
                type='email'
                required
                placeholder='אימייל'
                icon={<IconMail size={14} />}
                {...field}
              />
            )}
          />
          <Controller
            name='password'
            control={control}
            rules={{
              required: 'שדה זה הוא חובה',
            }}
            render={({ field }) => (
              <TextInput
                placeholder='סיסמה'
                type='password'
                label='סיסמה'
                icon={<IconLock size={14} />}
                {...field}
              />
            )}
          />
          <Text id='error-message' hidden={!isError} size='sm' color='red'>
            האיימיל או הסיסמה שגויים
          </Text>
          <Button
            loading={isLoading}
            type='submit'
            color='orange'
            my='sm'
            p={0}
            variant='outline'
            id='login-button'
          >
            התחבר
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
