import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Switch, Text } from '@mantine/core';
import { isEmpty, isEqual, toPairs } from 'lodash';
import React, { memo, useCallback, useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import ColorSelector from '~/components/ColorSelector';
import FileUpload from '~/components/FileUpload';
import { ImageTypes } from '~/components/FileUpload/FileUpload';
import FontPicker from '~/components/FontPicker';
import { FormState } from '~/forms/formState';
import { Palette } from '~/models/Palette';
import { Theme, defaultTheme } from '~/models/Theme';
import { menuDesignValidations } from './validations';

const schema: yup.AnyObjectSchema = yup
  .object()
  .shape(menuDesignValidations, [['notice', 'notice']])
  .required();

interface MenuThemeFormProps {
  menuTheme: Theme;
  selectedPalette: Palette | undefined;
  initialMenuTheme: Theme | null;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  dispatchMenuTheme: React.Dispatch<any>;
  setFormState: React.Dispatch<React.SetStateAction<FormState | null>>;
}

export const MenuDesignForm: React.FC<MenuThemeFormProps> = memo(
  ({
    menuTheme,
    selectedPalette,
    initialMenuTheme,
    dispatchMenuTheme,
    setFormState,
  }: MenuThemeFormProps) => {
    const { register, watch, reset, control, setValue, getValues } = useForm<Theme>({
      defaultValues: { ...defaultTheme, ...menuTheme },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
    });

    const { errors, isValid, isDirty, isValidating } = useFormState({ control });

    useEffect(() => {
      const subscription = watch((data) => {
        dispatchMenuTheme({ type: 'update', updated: data as Theme });
      });
      return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
      setFormState({ errors, isDirty, isValid });
    }, [errors, isValid, isDirty, isValidating]);

    useEffect(() => {
      if (!isEmpty(selectedPalette)) {
        toPairs(selectedPalette).map(([key, value]) => {
          setValue(key as keyof Theme, value, { shouldDirty: true });
        });
      }
    }, [selectedPalette]);

    const uploadImage = useCallback(
      (field: keyof Theme, file: ImageTypes) => {
        setValue(field, file, {
          shouldDirty: true,
        });
      },
      [menuTheme],
    );

    useEffect(() => {
      if (initialMenuTheme) {
        reset({ ...defaultTheme, ...initialMenuTheme });
      }
    }, [initialMenuTheme]);

    useEffect(() => {
      if (menuTheme && !isEqual(menuTheme, getValues())) {
        reset({ ...defaultTheme, ...menuTheme });
      }
    }, [menuTheme]);

    return (
      <Box>
        <Controller
          name='backgroundColor'
          control={control}
          render={({ field }) => (
            <ColorSelector
              label='צבע רקע'
              placeholder='בחרו צבע רקע'
              error={errors.backgroundColor?.message}
              props={{ ...field }}
            />
          )}
        />
        <Controller
          name='primaryColor'
          control={control}
          render={({ field }) => (
            <ColorSelector
              label='צבע ראשי'
              placeholder='בחרו צבע ראשי'
              error={errors.primaryColor?.message}
              props={{ ...field }}
            />
          )}
        />
        <Controller
          name='secondaryColor'
          control={control}
          render={({ field }) => (
            <ColorSelector
              label='צבע משני'
              placeholder='בחרו צבע משני'
              error={errors.secondaryColor?.message}
              props={{ ...field }}
            />
          )}
        />
        <Switch my='sm' label='קו הפרדה - קו הפרדה בין פריטים' {...register('showDividers')} />
        <Box my='md' sx={{ maxWidth: 'fit-content' }}>
          <Text size='sm'>פונט</Text>
          <Controller
            name='font'
            control={control}
            render={({ field }) => (
              <FontPicker
                defaultValue={menuTheme?.font}
                onSelect={(value: string) => field.onChange(value)}
              />
            )}
          />
        </Box>
        <Box my='xl'>
          <Text>תמונת כותרת (Cover)</Text>
          <FileUpload
            uploadImage={(file) => uploadImage('cover', file)}
            defaultImage={watch('cover') ?? null}
          />
        </Box>

        <Box my='xl'>
          <Text>תמונת רקע</Text>
          <FileUpload
            uploadImage={(file) => uploadImage('backgroundImage', file)}
            defaultImage={watch('backgroundImage') ?? null}
          />
        </Box>
      </Box>
    );
  },
);
