import validator from 'validator';
import * as yup from 'yup';
import { Theme } from '~/models/Theme';
import { validationsMessages } from '../validationsMessages';

export const menuDesignValidations: Record<keyof Theme, any> = {
  backgroundColor: yup
    .string()
    .required(validationsMessages.required())
    .test('isHexColor', validationsMessages.invalidHexColor(), (value: string | undefined) =>
      validator.isHexColor(value ?? ''),
    ),
  primaryColor: yup
    .string()
    .required(validationsMessages.required())
    .test('isHexColor', validationsMessages.invalidHexColor(), (value: string | undefined) =>
      validator.isHexColor(value ?? ''),
    ),
  secondaryColor: yup
    .string()
    .required(validationsMessages.required())
    .test('isHexColor', validationsMessages.invalidHexColor(), (value: string | undefined) =>
      validator.isHexColor(value ?? ''),
    ),
  font: yup.string().required(validationsMessages.required()),
  showDividers: yup.bool().notRequired(),
  cover: undefined,
  backgroundImage: undefined,
};
