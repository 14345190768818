interface PaymentRequiredProps {
  children: JSX.Element;
}

export const PaymentRequired = ({ children }: PaymentRequiredProps) => {
  // const navigate = useNavigate();
  // const { selectedBusiness } = useBusinesses();
  // const [isPaymentModalOpened, togglePaymentModalOpened] = useToggle();

  // const closePaymentModal = () => {
  //   togglePaymentModalOpened();
  //   navigate('/');
  // };

  // useEffect(() => {
  //   if (selectedBusiness) {
  //     if (!businessUtils.isBusinessActive(selectedBusiness)) {
  //       togglePaymentModalOpened();
  //     }
  //   }
  // }, [selectedBusiness]);

  return children;

  // return (
  //   <Flex sx={{ flex: 1 }}>
  //     {/* <PaymentModal
  //       isOpened={isPaymentModalOpened}
  //       onClose={() => closePaymentModal()}
  //       description={
  //         <Box>
  //           <Text size='lg' color='red'>
  //             לעסק {selectedBusiness?.name} הסתיים המנוי!
  //           </Text>
  //           <Text size='md'>
  //             המנוי שלכם הסתיים עבור עסק זה. בבקשה מלאו את פרטי התשלום על מנת להמשיך.
  //           </Text>
  //         </Box>
  //       }
  //     /> */}
  //     {children}
  //   </Flex>
  // );
};
