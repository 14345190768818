import {
  Box,
  Divider,
  Flex,
  MantineColor,
  MantineSize,
  Space,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { TablerIcon } from '@tabler/icons';
import React from 'react';

interface DashboardHeaderProps {
  title: string;
  icon: TablerIcon;
  titleSize?: MantineSize | number;
  iconSize?: number;
  underline?: boolean;
  color?: MantineColor;
  mid?: React.ReactNode;
  children?: React.ReactNode;
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  title,
  icon,
  titleSize,
  iconSize,
  color,
  underline = true,
  mid,
  children,
}: DashboardHeaderProps) => {
  const theme = useMantineTheme();

  return (
    <div>
      <Flex justify='space-between' align='center' py='xs'>
        <Flex align='center' sx={{ color: color ?? theme.colors.orange[5] }}>
          {React.createElement(icon, { size: iconSize ?? 28 })}
          <Title px='xs' order={2} size={titleSize}>
            {title}
          </Title>
        </Flex>
        {mid ? <Box>{mid}</Box> : null}
        <Flex align='center'>{children}</Flex>
      </Flex>
      {underline ? (
        <>
          <Space h='xs' />
          <Divider />
        </>
      ) : null}
      {!titleSize && !!underline && <Space h='xs' />}
    </div>
  );
};
