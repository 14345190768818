import { yupResolver } from '@hookform/resolvers/yup';
import { Flex, MultiSelect, Text, Textarea, TextInput } from '@mantine/core';
import { IconChevronDown, IconCurrencyShekel } from '@tabler/icons';
import { isEmpty, toPairs } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import FileUpload from '~/components/FileUpload';
import { ImageTypes } from '~/components/FileUpload/FileUpload';
import {
  availableMenuProperties,
  MenuNode,
  MenuNodeTypes,
  MenuPropertiesKeys,
  newMenuNodeCategory,
  newMenuNodeItem,
} from '~/models/Menu';
import { validationsMessages } from '../validationsMessages';
import { MenuNodeFormModel } from './interfaces';
import { menuNodeValidations } from './validations';

const schema: yup.AnyObjectSchema = yup
  .object()
  .shape(menuNodeValidations, [
    ['description', 'description'],
    ['price', 'price'],
    ['badge', 'badge'],
  ])
  .required();

interface MenuNodeFormProps {
  menuType: MenuNodeTypes;
  setIsValid: (valid: boolean, errors: any) => void;
  dispatchMenuData: React.Dispatch<any>;
  defaultData: Omit<MenuNode, 'id' | 'type' | 'children'>;
  nodeValidations: { valid: boolean; errors: any };
}

export const MenuNodeForm: React.FC<MenuNodeFormProps> = ({
  menuType,
  setIsValid,
  dispatchMenuData,
  defaultData,
  nodeValidations,
}: MenuNodeFormProps) => {
  const { register, watch, reset, setValue, control, setFocus, setError } =
    useForm<MenuNodeFormModel>({
      defaultValues: {
        ...(menuType === 'category' ? newMenuNodeCategory() : newMenuNodeItem()),
        ...defaultData,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { errors, isValid, isDirty, isValidating } = useFormState({ control });

  const isReset = useRef<boolean>(false);

  useEffect(() => {
    const subscription = watch((data) => {
      if (!isReset.current) {
        dispatchMenuData({
          type: menuType === 'item' ? 'updateNode' : 'updateCategory',
          node: defaultData,
          updatedNode: data as MenuNodeFormModel,
        });
      } else {
        isReset.current = false;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultData]);

  useEffect(() => {
    if (setIsValid) {
      setIsValid(isDirty && isValid && isEmpty(errors), errors);
    }
  }, [isDirty, isValid, errors, isValidating]);

  useEffect(() => {
    setFocus('title');
    isReset.current = true;
    reset(defaultData);
    if (nodeValidations?.errors) {
      toPairs(nodeValidations.errors).map((pair) => setError(pair[0] as any, pair[1] as any));
    }
    if (!nodeValidations?.errors.title && isEmpty(defaultData.title.trim())) {
      setError('title', { type: 'required', message: validationsMessages.required() });
    }
  }, [defaultData]);

  const uploadImage = useCallback(
    (file: ImageTypes) => {
      setValue('image', file, { shouldDirty: true, shouldValidate: true });
    },
    [defaultData],
  );

  return (
    <Flex direction='column' sx={{ flex: 1 }}>
      <TextInput
        placeholder={menuType === 'item' ? 'שם הפריט' : 'שם הקטגוריה'}
        label={menuType === 'item' ? 'שם הפריט' : 'שם הקטגוריה'}
        type='text'
        withAsterisk
        required
        error={errors.title?.message}
        {...register('title')}
      />

      <Textarea
        placeholder='תיאור'
        label='תיאור'
        autosize
        minRows={2}
        maxRows={4}
        error={errors.description?.message}
        {...register('description')}
      />

      {menuType === 'item' && (
        <>
          <TextInput
            placeholder='מחיר'
            label='מחיר'
            type='text'
            icon={<IconCurrencyShekel />}
            error={errors.price?.message}
            {...register('price')}
          />
          <TextInput
            placeholder='תגית'
            label='תגית'
            type='text'
            error={errors.badge?.message}
            {...register('badge')}
          />

          <MultiSelect
            data={availableMenuProperties}
            label='תכונות הפריט'
            placeholder='בחרו את תכונות הפריט'
            rightSection={<IconChevronDown size={14} />}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            rightSectionWidth={40}
            value={watch('properties')}
            defaultValue={defaultData.properties}
            onChange={(value: string[]) => setValue('properties', value as MenuPropertiesKeys[])}
          />
        </>
      )}

      <Text size='sm'>תמונה</Text>
      <FileUpload uploadImage={uploadImage} defaultImage={watch('image') ?? null} />
    </Flex>
  );
};
