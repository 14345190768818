import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuConfig, MenuData, MenuNode } from '~/models/Menu';
import * as _ from 'lodash';
import { useBusinesses } from './Businesses';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';
import { cloneDeep, isEmpty, isString, omit } from 'lodash';
import { Theme } from '~/models/Theme';
import { menusApi } from '~/api/menusApi';
import { imagesApi } from '~/api/imagesApi';
import { useLocalStorage } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Business } from '~/models/Business';
import { MenuSettingsFormModel } from '~/forms/MenuSettingsForm/interfaces';
import { businessesApi } from '~/api/businessesApi';
import { analyticsApi } from '~/api/analyticsApi';
import { menuNodesUtils } from '~/utils/menuNodesUtils';
import { FileWithPath } from '@mantine/dropzone';
import isURL from 'validator/lib/isURL';

const MenuContext = React.createContext<{
  selectedMenu: Menu | null;
  selectMenu: (menu: Menu | null) => void;
  saveMenu: (
    data: {
      [type: string]: MenuData | Theme | MenuConfig | MenuSettingsFormModel;
    },
    requestedMenuSlug?: string,
  ) => void;
}>({
  selectedMenu: null,
  selectMenu: () => undefined,
  saveMenu: () => undefined,
});

export const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const { selectedBusiness } = useBusinesses();
  const [savedMenu, setSavedMenu] = useLocalStorage({ key: 'mid', defaultValue: '' });
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
  const [selectedMenuSlug, setSelectedMenuSlug] = useState<string>('');
  const [currentBusiness, setCurrentBusiness] = useState<Business | null>(null);

  useQuery(['fetchMenu', currentBusiness], {
    queryFn: () => {
      const slug = !isEmpty(selectedMenuSlug)
        ? selectedMenuSlug
        : !isEmpty(savedMenu)
        ? savedMenu
        : currentBusiness?.defaultMenu ?? '';

      if (currentBusiness && currentBusiness.active && !isEmpty(slug)) {
        return menusApi.fetchActiveMenu(currentBusiness.id, slug);
      } else {
        return null;
      }
    },
    onSuccess: (menu: Menu) => {
      selectMenu(menu);
    },
    onError: () => {
      selectMenu(null);
    },
    enabled: !!currentBusiness,
  });

  const selectMenu = (menu: Menu | null) => {
    setSelectedMenu(menu);
    setSelectedMenuSlug(menu?.slug ?? '');
    setSavedMenu(menu?.slug ?? '');
  };

  const fetchMenu = useMutation({
    mutationFn: async (slug: string) => {
      if (selectedBusiness) {
        return menusApi.fetchActiveMenu(selectedBusiness.id, slug);
      } else {
        return null;
      }
    },
  });

  const saveMenu = async (
    data: {
      [type: string]: MenuData | Theme | MenuConfig | MenuSettingsFormModel;
    },
    requestedMenuSlug?: string,
  ) => {
    let menu: Menu;
    if (requestedMenuSlug) {
      const requestedMenu = await fetchMenu.mutateAsync(requestedMenuSlug);
      menu = { ...requestedMenu, ...omit(data, 'settings') } as Menu;
    } else {
      menu = { ...selectedMenu, ...omit(data, 'settings') } as Menu;
    }
    if (data.settings) {
      menu = { ...menu, ...data.settings };
    }
    if (!menu || !menu.data || !menu.theme || !menu.config || !(menu.slug && menu.displayName)) {
      showNotification({
        color: 'red',
        title: 'לא ניתן לשמור את התפריט',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
      return;
    }

    showNotification({
      id: 'save-menu',
      title: 'שומר תפריט',
      message: 'המערכת שומרת את התפריט...',
      loading: true,
      autoClose: false,
      disallowClose: true,
    });
    if (selectedBusiness) {
      if (selectedMenu && data.settings) {
        const updatedMenu = await menusApi.updateMenuSetings(
          selectedBusiness.id,
          selectedMenu.slug,
          {
            slug: menu.slug,
            displayName: menu.displayName,
          },
        );

        if (updatedMenu) {
          if (
            menu.slug !== selectedMenu.slug &&
            selectedBusiness.defaultMenu === selectedMenu.slug
          ) {
            await analyticsApi.updateMenuAnalytics(
              selectedBusiness.id,
              selectedMenu.slug,
              menu.slug,
            );
            await businessesApi.updateDefaultMenu(selectedBusiness.id, menu.slug);
          }
        }
      }

      if (
        (menu.theme.cover && !isString(menu.theme.cover)) ||
        (menu.theme.cover && isString(menu.theme.cover) && isURL(menu.theme.cover ?? ''))
      ) {
        const uploadedImages = await imagesApi.uploadImages([
          { id: 'image', data: menu.theme.cover },
        ]);
        menu.theme.cover = _.get(uploadedImages, 'image');
      }

      if (
        (menu.theme.backgroundImage && !isString(menu.theme.backgroundImage)) ||
        (menu.theme.backgroundImage &&
          isString(menu.theme.backgroundImage) &&
          isURL(menu.theme.backgroundImage ?? ''))
      ) {
        const uploadedImages = await imagesApi.uploadImages([
          { id: 'image', data: menu.theme.backgroundImage },
        ]);
        menu.theme.backgroundImage = _.get(uploadedImages, 'image');
      }

      const images: { id: string; data: string | FileWithPath | File }[] = [];

      menu.data.map(async (category: MenuNode) => {
        if (category.children) {
          category.children.map(async (children: MenuNode) => {
            if (
              (children.image && !isString(children.image)) ||
              (children.image && isString(children.image) && isURL(children.image ?? ''))
            ) {
              images.push({ id: children.id, data: children.image });
            }
          });
        }

        if (
          (category.image && !isString(category.image)) ||
          (category.image && isString(category.image) && isURL(category.image ?? ''))
        ) {
          images.push({ id: category.id, data: category.image });
        }
      });

      let uploadedImages: Record<string, string> = {};

      const uploadedChunks = await Promise.all(
        _.chain(images)
          .chunk(20)
          .map((chunk) => imagesApi.uploadImages(chunk))
          .value(),
      );

      for (const chunk of uploadedChunks) {
        uploadedImages = { ...uploadedImages, ...chunk };
      }

      menu.data = menu.data.map((category: MenuNode) => {
        if (category.children) {
          category.children = category.children.map((child: MenuNode) => {
            if (_.has(uploadedImages, child.id)) {
              const newChild = cloneDeep(child);
              newChild.image = _.get(uploadedImages, child.id);
              return newChild;
            }
            return child;
          });
        }

        if (_.has(uploadedImages, category.id)) {
          category.image = _.get(uploadedImages, category.id);
        }

        return category;
      });

      const newMenu: Menu = await menusApi.createMenu(selectedBusiness!.id, {
        displayName: menu.displayName,
        slug: menu.slug,
        data: menu.data,
        theme: menu.theme,
        config: menu.config,
      });

      await queryClient.invalidateQueries({
        queryKey: ['fetchMenus', selectedBusiness],
        exact: true,
      });
      selectMenu({
        ...newMenu,
        data: menuNodesUtils.makeMenuDataEditable(newMenu.data),
      });

      updateNotification({
        id: 'save-menu',
        color: 'green',
        title: 'התפריט עודכן בהצלחה!',
        message: '',
        icon: <IconCheck size={18} />,
        autoClose: 5000,
      });
    } else {
      updateNotification({
        id: 'save-menu',
        color: 'red',
        title: 'לא ניתן לשמור את התפריט',
        message: '',
        icon: <IconX size={18} />,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    setCurrentBusiness(selectedBusiness);
  }, [selectedBusiness]);

  const value = {
    selectedMenu,
    selectMenu,
    saveMenu,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export function useMenu() {
  return useContext(MenuContext);
}
