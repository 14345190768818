import * as yup from 'yup';
import { MenuNodeFormModel } from './interfaces';
import { validationsMessages } from '../validationsMessages';
import { availableMenuProperties } from '~/models/Menu';
import { keys } from 'lodash';

export const menuNodeValidations: Record<keyof MenuNodeFormModel, any> = {
  title: yup
    .string()
    .min(2, validationsMessages.minLength(2))
    .max(50, validationsMessages.maxLength(50))
    .required(validationsMessages.required()),
  description: yup
    .string()
    .notRequired()
    .when('description', {
      is: (value: string) => value?.length,
      then: yup
        .string()
        .min(2, validationsMessages.minLength(2))
        .max(300, validationsMessages.maxLength(300)),
    }),
  price: yup
    .mixed()
    .notRequired()
    .when('price', {
      is: (value: string) => value?.length,
      then: yup
        .string()
        .min(1, validationsMessages.minLength(1))
        .max(30, validationsMessages.maxLength(30)),
    }),
  badge: yup
    .string()
    .notRequired()
    .when('badge', {
      is: (value: string) => value?.length,
      then: yup
        .string()
        .min(2, validationsMessages.minLength(2))
        .max(50, validationsMessages.maxLength(50)),
    }),
  properties: yup
    .array()
    .of(yup.string().oneOf(keys(availableMenuProperties), validationsMessages.invalidProperty())),
  image: undefined,
};
