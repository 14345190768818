import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '~/contexts/Auth';

interface PrivateRouteProps {
  component: JSX.Element;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component }: PrivateRouteProps) => {
  const { user } = useAuth();
  return user ? component : <Navigate to='/login' replace={true} />;
};
